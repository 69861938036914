import React, { useEffect } from 'react';
import Utenti from './Utenti';

export default function Pazienti(props) {
    // region variables

    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {}, []);
    // endregion

    // region functions

    // endregion
    return <Utenti pazienti />;
}
