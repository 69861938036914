import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class Avviso extends Component {

    render() {
        return (
            <Modal isOpen={this.props.attivo} >
                <ModalHeader>{this.props.titolo}</ModalHeader>
                <ModalBody>
                    {this.props.messaggio}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.props.toggle()}>OK</Button>{' '}
                </ModalFooter>
            </Modal>
      )
    }

}
