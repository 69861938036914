import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, ListGroupItemText, Row } from 'reactstrap';
import TooltipItem from '../../common/TooltipItem';
import StudentsPoints from '../Planning/DlgComponents/StudentsPoints';
import PlanningSchedaStudente from '../Planning/DlgComponents/PlanningSchedaStudente';
import moment from 'moment';
import axios from 'axios';
import { apiconfig } from '../../shared/apiconfig';
import { downloadAllegatoMaster, URL_ALLEGATI } from '../../services/commons';
import ModaleVisualizzaAllegato from '../Allegati/Components/ModaleVisualizzaAllegato';
import ModalSpinner from '../../common/ModalSpinner';

export default function SingleReport(props) {
    // region variables
    const { evento, planningAdmin, idDocente, user, evaluation, studente, readOnly } = props;
    const [state, setState] = useState({});
    const [allegatoState, setAllegatoState] = useState({});
    const [selectedMat, setSelectedMat] = useState('');
    const [visible, setVisible] = useState(false);
    const [dataToSend, setDataToSend] = useState('');
    const [avviso, setAvviso] = useState({});
    const [annotazioni, setAnnotazioni] = useState('');
    const [loading, setLoading] = useState(false);
    // endregion
    /*
* {
    "id_planning": 22124,
    "valutazioni": [
        {
            "id": 9922,
            "id_docente": 311,
            * messaggio
            "annotazioni": "test",
            "valutazioni": [
                {
                    "Il cervello": [
                        {
                            "name": "Attenzione_studente",
                            "points": 2,
                            "info": " 1 \u2013 2: Minima attenzione \n 3 - 4: Poca attenzione \n 5 \u2013 6: Discreta attenzione\n 7 \u2013 8: Buona attenzione \n9 \u2013 10: Ottima attenzione \n"
                        },
                        {
                            "name": "Comprensione_argomenti",
                            "points": 3,
                            "info": " 1 \u2013 2: Minima comprensione dell\u2019argomento \n 3 \u2013 4: Poca comprensione dell\u2019argomento\n 5 - 6: Discreta comprensione dell\u2019argomento\n 7 - 8: Buona comprensione dell\u2019argomento\n9 - 10: Ottima comprensione dell\u2019argomento\n"
                        },
                        {
                            "name": "Completamento_argomenti_della_lezione",
                            "points": 3,
                            "info": " 1 - 2: Minimo completamento argomenti della lezione 10-20 %\n 3 - 4: Insufficiente completamento argomenti della lezione 30-40 %\n 5 - 6: Discreto completamento argomenti della lezione 50-60 %\n 7 \u2013 8: Buono completamento argomenti della lezione  70-80 %\n9 - 10: Ottimo completamento argomenti della lezione 90-100 %\n"
                        },
                        {
                            "name": "Completamento_esercizi_della_lezione",
                            "points": 0,
                            "info": " 1 - 2: Minimo completamento esercizi della lezione 10-20 %\n 3 - 4: Insufficiente completamento esercizi della lezione 30-40 %\n 5 - 6: Discreto completamento esercizi della lezione 50-60 %\n 7 - 8: Buon completamento esercizi della lezione 70-80 %\n9 - 10: Ottimo completamento esercizi della lezione 90-100 %\n"
                        },
                        {
                            "name": "Padronanza_argomento_trattato ",
                            "points": 5,
                            "info": " 1 - 2: Minima padronanza argomento trattato 10-20 %\n 3 - 4: Insufficiente padronanza argomento trattato 30-40%\n 5 - 6: Discreta padronanza argomento trattato 50-60%\n 7 - 8: Buona padronanza argomento trattato 70-80%\n9 - 10: Ottima padronanza argomento trattato 90-100% \n"
                        }
                    ]
                }
            ]
        }
    ],
    "attiva": 1
}
*
* */
    // region lifeCycle
    // onStart
    function salvaAnnotazioniStudente(arg, extra, callback) {
        let params = {
            id: planningAdmin.id,
            annotazioni,
            id_studente: studente?.id,
            ...extra,
            idDocente
        };
        axios
            .patch(apiconfig().baseURL + 'planningannotazionistudentedoc', params)
            .then(() => {
                props.setAvviso({
                    attivo: true,
                    titolo: 'Successo',
                    messaggio: 'Valutazione modificata con successo'
                });
            })
            .catch(console.warn);
    }
    useEffect(() => {
        /**/
    }, []);

    // endregion

    // region functions
    const scaricaAllegatoPlanning = (id, name) => {
        setLoading(true);
        downloadAllegatoMaster(id, name, URL_ALLEGATI.PLANNING)
            .then(data => {
                if (data)
                    setAllegatoState({
                        modaleVisualizzaAllegato: data,
                        modaleVisualizzaAllegatoIsPdf: name.includes('.pdf'),
                        modaleVisualizzaAllegatoName: name
                    });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    function removeAllegatoPlanningHandler(arg) {
        props.setRequest({
            attivo: true,
            titolo: 'Richiesta conferma',
            messaggio: "Eliminare l'allegato?",
            azione: 3,
            idAllegato: arg,
            callback: () =>
                axios
                    .post(apiconfig().baseURL + 'rimuoviallegatoplanning/' + arg, {})
                    .then(() => {
                        props.getData && props.getData();
                        props.setAvviso({
                            attivo: true,
                            titolo: 'Successo',
                            messaggio: 'Allegato eliminato correttamente'
                        });
                    })
                    .catch(console.warn)
        });
    }
    function toggleDropDown(asd) {}
    const mostraAggiungiAllegato = (idStudente, isNotLesson) => {
        props.setModaleAllegati({
            modaleAllegatiAttiva: true,
            isNotLesson,
            idStudenteModaleAllegati: idStudente,
            id_planning: evento.id,
            allegatiSelezionati: [],
            idMateriaAllegatoNuovo: selectedMat || props.evento.materie[0].id
        });
    };
    // endregion
    let docenti2 = evento?.docenti?.find(el => el.id == idDocente);
    return (
        <div
            style={{
                maxHeight: !visible ? 57 : 1100 + (props.evaluation?.allegati?.length || 0) * 75,
                transition: '0.5s',
                overflow: 'hidden',
                boxShadow: '1px 1px 3px #0005',
                cursor: 'pointer',
                margin: 16,
                borderRadius: 16
            }}
        >
            <div
                onClick={() => setVisible(!visible)}
                className={'report-title hover-grey hover-icon-white'}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', height: 57 }}
            >
                <h5 style={{ marginLeft: 17.3, marginRight: 17.3, marginBottom: 0 }}>
                    <i className={'fa fa-graduation-cap'}></i>{' '}
                    {window.innerWidth > 568 ? (
                        <>
                            Lezione del {moment(evento.data_planning).format('DD/MM/YYYY')}
                            {' ore '}
                            {evento.start}
                        </>
                    ) : (
                        <></>
                    )}
                </h5>
                <a target={'_blank'} href={'#/datiutente/' + studente?.id}>
                    {(window.innerWidth > 520 ? 'Studente: ' : '') + studente?.cognome + ' ' + studente?.nome}
                </a>
                &nbsp; &nbsp; &nbsp;
                {docenti2 && !readOnly && (
                    <a target={'_blank'} href={'#/datiutente/' + docenti2.id}>
                        {(window.innerWidth > 520 ? 'Docente: ' : '') + docenti2.cognome + ' ' + docenti2.nome}
                    </a>
                )}
                <div
                    className={'pt-0 px-2 ml-auto cursor-pointer bg-light text-primary icon-container'}
                    style={{ marginRight: 20, borderRadius: 8, boxShadow: '1px 1px 3px #0000003d' }}
                >
                    <i className={'fa ' + (visible ? 'fa-minus' : 'fa-plus')} style={{ fontSize: 16, marginTop: 4 }} />
                </div>
            </div>
            {readOnly && evaluation.messaggio ? (
                <div className={'d-flex align-items-center'} style={{ marginLeft: 16, marginBottom: -12 }}>
                    <b>Messaggio dall'amministratore:</b>
                    <span
                        style={{
                            padding: 5,
                            marginLeft: 12,
                            borderColor: 'darkorange',
                            borderWidth: 2,
                            borderStyle: 'solid',
                            background: '#fa03',
                            borderRadius: 12,
                            paddingLeft: 12,
                            paddingRight: 12
                        }}
                    >
                        {evaluation.messaggio}
                    </span>
                    &nbsp;
                </div>
            ) : null}
            <ModalSpinner attivo={loading} />
            {evento.docenti ? (
                evento.docenti.find(el => el.id == idDocente) ? (
                    <React.Fragment>
                        <>
                            <Card
                                style={{ paddingTop: -0, margin: 0, backgroundColor: 'transparent', boxShadow: 'none' }}
                            >
                                <CardBody>
                                    <Row>
                                        <Col sm="12" xs="12" md="12">
                                            <StudentsPoints
                                                attivo={true}
                                                evaluation
                                                onRequest
                                                readOnly={readOnly}
                                                forceOpen
                                                setSelectedMat={setSelectedMat}
                                                selectedMat={selectedMat + ''}
                                                setInitialData={console.info}
                                                idDocenteLezioneVisualizzata={idDocente}
                                                docente={props.evento?.docenti && props.evento?.docenti[idDocente]}
                                                studente={studente}
                                                setTopic={setAnnotazioni}
                                                eventoDetail={evaluation.data}
                                                evento={{
                                                    materie: props.evento.materie || []
                                                }}
                                                studId={studente?.id}
                                                reset={console.info}
                                                newAdmin
                                                initSection={''}
                                                setSection={setDataToSend}
                                            />
                                            {!readOnly ? (
                                                <Button
                                                    color="primary"
                                                    onClick={arg =>
                                                        salvaAnnotazioniStudente(arg, {
                                                            annotazioni,
                                                            dati_materie: JSON.stringify(dataToSend)
                                                        })
                                                    }
                                                    disabled={false}
                                                >
                                                    Salva annotazioni
                                                </Button>
                                            ) : null}
                                            <PlanningSchedaStudente
                                                evento={props.evento}
                                                attivo={props.attivo}
                                                el={studente}
                                                state={state}
                                                setState={data => {
                                                    setState(data);
                                                }}
                                                idDocente={idDocente}
                                                props={props}
                                            />
                                        </Col>
                                        <Row
                                            style={{
                                                paddingRight: 0
                                            }}
                                        >
                                            <Col
                                                xs="12"
                                                md="12"
                                                sm="12"
                                                style={{
                                                    marginBottom: '16px',
                                                    display: 'flex',
                                                    marginRight: 0,
                                                    paddingRight: 0
                                                }}
                                            ></Col>
                                        </Row>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="12" sm="12">
                                            <Card>
                                                <CardHeader>Allegati docente</CardHeader>
                                                <CardBody>
                                                    {!readOnly && (
                                                        <Row>
                                                            <Col xs="12" md="12" sm="12">
                                                                <div className="mb-2">
                                                                    <Button
                                                                        id="btnAggiungiAllegato"
                                                                        size="sm"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            mostraAggiungiAllegato(studente?.id, false)
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                    <TooltipItem
                                                                        placement="right"
                                                                        objectId={'btnAggiungiAllegato'}
                                                                        content="Aggiungi allegati"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col xs="12" md="12" sm="12">
                                                            <ListGroup>
                                                                {planningAdmin.allegati
                                                                    ?.filter(x => x.id_studente == studente?.id)
                                                                    .map(allegato => {
                                                                        return (
                                                                            <ListGroupItem key={allegato.id}>
                                                                                <Button
                                                                                    color="link"
                                                                                    onClick={() =>
                                                                                        scaricaAllegatoPlanning(
                                                                                            allegato.id,
                                                                                            allegato.nome_file
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {allegato.nome_file}
                                                                                </Button>
                                                                                {!readOnly ? (
                                                                                    <Button
                                                                                        size="sm"
                                                                                        color="danger"
                                                                                        className="float-right"
                                                                                        onClick={() =>
                                                                                            removeAllegatoPlanningHandler(
                                                                                                allegato.id
                                                                                            )
                                                                                        }
                                                                                        id={
                                                                                            'EliminaAllegato_' +
                                                                                            allegato.id
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </Button>
                                                                                ) : null}
                                                                                <TooltipItem
                                                                                    placement="top"
                                                                                    objectId={
                                                                                        'EliminaAllegato_' + allegato.id
                                                                                    }
                                                                                    content="Rimuovi"
                                                                                />
                                                                                <ListGroupItemText>
                                                                                    {allegato.descrizione
                                                                                        ? allegato.descrizione
                                                                                        : ''}
                                                                                </ListGroupItemText>
                                                                            </ListGroupItem>
                                                                        );
                                                                    })}
                                                            </ListGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    {!readOnly ? (
                                        <div style={{ width: '100%' }} className={'d-flex justify-content-end'}>
                                            <Button
                                                className={'success-dlg-button ml-2'}
                                                onClick={() => {
                                                    let temp = {
                                                        id_planning: evento.id || planningAdmin.id,
                                                        valutazioni: [],
                                                        attiva: 1
                                                    };
                                                    let newVal = evaluation.data.map(elem => {
                                                        let elemToSend = { ...elem };
                                                        elemToSend.valutazioni = dataToSend[elem.id_materia];
                                                        elemToSend.annotazioni = annotazioni;
                                                        return elemToSend;
                                                    });
                                                    temp.valutazioni = newVal;
                                                    props.setModalEvaluation({
                                                        attivo: true,
                                                        data: temp
                                                    });
                                                }}
                                                title={'asd'}
                                            >
                                                Approva report
                                            </Button>
                                        </div>
                                    ) : null}
                                </CardBody>
                                <ModaleVisualizzaAllegato
                                    data={allegatoState.modaleVisualizzaAllegato}
                                    isPdf={allegatoState.modaleVisualizzaAllegatoIsPdf}
                                    name={allegatoState.modaleVisualizzaAllegatoName}
                                    close={() => {
                                        setAllegatoState({ modaleVisualizzaAllegato: null });
                                    }}
                                />
                            </Card>
                        </>
                    </React.Fragment>
                ) : (
                    <div>docente non trovato</div>
                )
            ) : (
                <div>no docenti</div>
            )}
        </div>
    );
}
