import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Row,
    Col,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Badge,
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import TooltipItem from './TooltipItem';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../shared/apiconfig';
// import Globalize from 'globalize';
// import globalizeLocalizer from 'react-widgets-globalize';
import MultiSelect from 'react-widgets/Multiselect';
import 'react-widgets/styles.css';

export default class FiltroAllegati extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipiAllegati: [],
            scuole: [],
            classi: [
                { id: '1', classe: '1' },
                { id: '2', classe: '2' },
                { id: '3', classe: '3' },
                { id: '4', classe: '4' },
                { id: '5', classe: '5' },
                { id: '6', classe: '6' }
            ],
            materie: [],
            descrizione: ''
        };
    }

    componentDidMount() {
        addAuthToken();
        this.caricaElenchi();
    }

    caricaElenchi = () => {
        axios
            .get(apiconfig().baseURL + 'tipiAllegati', {})
            .then(response => {
                this.setState({
                    tipiAllegati: response.data
                });
            })
            .catch(error => {
                if (error?.response?.status && error.response.status == 401) {
                    window.location.href = '#/logout';
                }
                console.error(error);
            });
        axios
            .get(apiconfig().baseURL + 'scuole', {})
            .then(response => {
                this.setState({
                    scuole: response.data
                });
            })
            .catch(error => {
                console.error(error);
            });
        axios
            .get(apiconfig().baseURL + 'materie', {})
            .then(response => {
                this.setState({
                    materie: response.data
                });
            })
            .catch(error => {
                console.error(error);
            });
        //todo aggiungere docentitut?
        if (this.props.studente)
            axios
                .get(apiconfig().baseURL + 'docentistud', {})
                .then(response => {
                    this.setState({
                        docenti: response.data.map(e => ({ ...e, nomeCompleto: e.nome + ' ' + e.cognome }))
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        else {
            let params = { params: { is_docente: 1 } };
            axios
                .get(apiconfig().baseURL + 'users', params)
                .then(response => {
                    this.setState({
                        docenti: response.data.map(e => ({ ...e, nomeCompleto: e.nome + ' ' + e.cognome }))
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    render() {
        return (
            <Card>
                <CardHeader>
                    <Label style={{ fontWeight: 'bold' }}>Filtri di ricerca documenti</Label>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="12" sm="6" md="6">
                            <FormGroup>
                                <Label htmlFor="filtroRicercaTipo">Tipo di allegato</Label>
                                <MultiSelect
                                    id="filtroRicercaTipo"
                                    data={this.state.tipiAllegati}
                                    value={this.props.filtriRicerca ? this.props.filtriRicerca.tipiAllegati : null}
                                    textField="nome"
                                    minLength={3}
                                    onChange={this.props.handleChangefiltroRicercaTipiAllegati}
                                />
                            </FormGroup>
                        </Col>
                        {!this.props.studente && (
                            <Col xs="12" md="6" sm="6">
                                <FormGroup>
                                    <Label htmlFor="filtroRicercaScuola">Scuola</Label>
                                    <MultiSelect
                                        id="filtroRicercaScuola"
                                        data={this.state.scuole}
                                        value={this.props.filtriRicerca ? this.props.filtriRicerca.scuole : null}
                                        textField="nome"
                                        minLength={3}
                                        onChange={this.props.handleChangefiltroRicercaScuole}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                        {!this.props.studente && (
                            <Col xs="12" md="6" sm="6">
                                <FormGroup>
                                    <Label htmlFor="filtroRicercaClasse">Classe</Label>
                                    <MultiSelect
                                        id="filtroRicercaClasse"
                                        data={this.state.classi}
                                        value={this.props.filtriRicerca ? this.props.filtriRicerca.classi : null}
                                        textField="classe"
                                        minLength={3}
                                        onChange={this.props.handleChangefiltroRicercaClassi}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                        <Col xs="12" md="6" sm="6">
                            <FormGroup>
                                <Label htmlFor="filtroRicercaMateria">Materia</Label>
                                <MultiSelect
                                    id="filtroRicercaMaterie"
                                    data={this.state.materie}
                                    value={this.props.filtriRicerca ? this.props.filtriRicerca.materie : null}
                                    textField="materia"
                                    minLength={3}
                                    onChange={this.props.handleChangefiltroRicercaMaterie}
                                />
                            </FormGroup>
                        </Col>
                        {!this.props.studente && (
                            <Col xs="12" md="6" sm="6">
                                <FormGroup>
                                    <Label htmlFor="filtroRicercaDocente">Docente</Label>
                                    <MultiSelect
                                        id="filtroRicercaDocente"
                                        data={this.state.docenti}
                                        value={this.props.filtriRicerca ? this.props.filtriRicerca.docente : null}
                                        textField="nomeCompleto"
                                        minLength={3}
                                        onChange={this.props.handleChangefiltroRicercaDocenti}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                        <Col xs="12" md="6" sm="6">
                            <FormGroup>
                                <Label htmlFor="filtroRicercaDescrizione">Descrizione</Label>
                                <Input
                                    id="filtroRicercaDescrizione"
                                    type="text"
                                    value={this.props.filtriRicerca ? this.props.filtriRicerca.descrizione : null}
                                    onChange={this.props.handleChangefiltroRicercaDescrizione}
                                />
                            </FormGroup>
                        </Col>
                        {!this.props.studente && (
                            <Col xs="12" md="12" sm="12">
                                <Row style={{ padding: '0 15px' }}>
                                    <Col xs="12" md="6" sm="6">
                                        <FormGroup check className="checkbox mb-3">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkSostegno"
                                                name="checkSostegno"
                                                checked={
                                                    this.props.filtriRicerca ? this.props.filtriRicerca.sostegno : false
                                                }
                                                onChange={this.props.handleChangefiltroRicercaSostegno}
                                            />
                                            <Label check className="form-check-label" htmlFor="checkSostegno">
                                                Mostra solo i documenti per sostegno
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        );
    }
}
