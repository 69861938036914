import React, { useEffect } from 'react';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
const giorni = ['Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];
const giorniMap = { Lunedi: 1, Martedi: 2, Mercoledi: 3, Giovedi: 4, Venerdi: 5, Sabato: 6 };
const hours = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00'
];
export default function ModaleGestioneCorsi({ attivo, titolo, conferma, close, messaggio, data, setData }) {
    const [selectedDays, setSelectedDays] = React.useState([]);
    const [hourFieldsDa, setHourFieldsDa] = React.useState({
        lunedi: [''],
        martedi: [''],
        mercoledi: [''],
        giovedi: [''],
        venerdi: [''],
        sabato: ['']
    });
    const [hourFieldsA, setHourFieldsA] = React.useState({
        lunedi: [''],
        martedi: [''],
        mercoledi: [''],
        giovedi: [''],
        venerdi: [''],
        sabato: ['']
    });
    return (
        <Modal style={{ maxWidth: 'unset', minWidth: 450, width: 'max-content' }} isOpen={attivo}>
            <Form
                action=""
                onSubmit={e => {
                    var object = {};
                    let formData = new FormData(e.target);
                    formData.forEach(function (value, key) {
                        object[key] = value;
                    });
                    var json = JSON.stringify(object);
                    let objToSend = {};
                    Object.keys(hourFieldsDa).forEach(function (key) {
                        if (!objToSend[key]) objToSend[key] = [];
                        hourFieldsDa[key].map((e, i) => {
                            objToSend[key].push(e + '-' + hourFieldsA[key][i]);
                        });
                    });
                    e.preventDefault();
                }}
                className="form-horizontal"
            >
                <ModalHeader>{titolo}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs="12" sm="12" md="12">
                            <FormGroup>
                                <Label htmlFor="nome">Nome</Label>
                                <Input
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    // value={data.nome || ''}
                                    // onChange={ev => setData({ ...data, nome: ev.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        {/*<Col xs="12" sm="7" md="7">*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label htmlFor="giorniCorsi">Giorni settimanali del corso:</Label>*/}
                        {/*        <MultiSelect*/}
                        {/*            id="giorniCorsi"*/}
                        {/*            label={'Giorni settimanali'}*/}
                        {/*            data={giorni}*/}
                        {/*            value={selectedDays || []}*/}
                        {/*            textField="nome"*/}
                        {/*            minLength={3}*/}
                        {/*            onChange={setSelectedDays}*/}
                        {/*        />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                    </Row>
                    {/*<Row>*/}
                    {/*    {selectedDays*/}
                    {/*        .sort((a, b) => (giorniMap[a] > giorniMap[b] ? 1 : -1))*/}
                    {/*        .map(e => (*/}
                    {/*            <div style={{ flex: 1, maxWidth: '50%' }}>*/}
                    {/*                <Label htmlFor={giorniMap[e] + 'hour'} style={{ flex: 1 }}>*/}
                    {/*                    {e}*/}
                    {/*                </Label>*/}
                    {/*                {hourFieldsDa[e.toLowerCase()].map((field, i) => (*/}
                    {/*                    <FormGroup style={{ display: 'flex', alignItems: 'center', minWidth: 230 }}>*/}
                    {/*                        <InputGroup style={{ flex: 1, minWidth: 80 }}>*/}
                    {/*                            <Input*/}
                    {/*                                type="select"*/}
                    {/*                                id={giorniMap[e] + 'hour_' + i}*/}
                    {/*                                name={'ora_lunedi_da'}*/}
                    {/*                                value={hourFieldsDa[e.toLowerCase()][i]}*/}
                    {/*                                onChange={event => {*/}
                    {/*                                    let temp = hourFieldsDa[e.toLowerCase()];*/}
                    {/*                                    temp[i] = event.target.value;*/}
                    {/*                                    setHourFieldsDa({ ...hourFieldsDa, [e.toLowerCase()]: temp });*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                <option value=""></option>*/}
                    {/*                                {hours.map(el => {*/}
                    {/*                                    return (*/}
                    {/*                                        <option key={el + e + i} value={el}>*/}
                    {/*                                            {el}*/}
                    {/*                                        </option>*/}
                    {/*                                    );*/}
                    {/*                                })}*/}
                    {/*                            </Input>*/}
                    {/*                        </InputGroup>*/}
                    {/*                        <div style={{ maxWidth: 30, width: 30, textAlign: 'center' }}>-</div>*/}
                    {/*                        <InputGroup*/}
                    {/*                            style={{*/}
                    {/*                                flex: 1.3,*/}
                    {/*                                minWidth: 80*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            <Input*/}
                    {/*                                type="select"*/}
                    {/*                                id={giorniMap[e] + 'hour_' + i}*/}
                    {/*                                name={'ora_lunedi_a'}*/}
                    {/*                                value={hourFieldsA[e.toLowerCase()][i]}*/}
                    {/*                                onChange={event => {*/}
                    {/*                                    let temp = hourFieldsA[e.toLowerCase()];*/}
                    {/*                                    temp[i] = event.target.value;*/}
                    {/*                                    setHourFieldsA({ ...hourFieldsA, [e.toLowerCase()]: temp });*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                <option value=""></option>*/}
                    {/*                                {hours.map(el => {*/}
                    {/*                                    return (*/}
                    {/*                                        <option key={el + e + i} value={el}>*/}
                    {/*                                            {el}*/}
                    {/*                                        </option>*/}
                    {/*                                    );*/}
                    {/*                                })}*/}
                    {/*                            </Input>*/}
                    {/*                            <Button*/}
                    {/*                                className={*/}
                    {/*                                    i !== hourFieldsDa[e.toLowerCase()].length - 1 &&*/}
                    {/*                                    'error-dlg-button'*/}
                    {/*                                }*/}
                    {/*                                color={*/}
                    {/*                                    i === hourFieldsDa[e.toLowerCase()].length - 1*/}
                    {/*                                        ? 'primary'*/}
                    {/*                                        : 'error'*/}
                    {/*                                }*/}
                    {/*                                onClick={ev => {*/}
                    {/*                                    if (i !== hourFieldsDa[e.toLowerCase()].length - 1) return;*/}
                    {/*                                    let temp = hourFieldsDa[e.toLowerCase()];*/}
                    {/*                                    temp.push('');*/}
                    {/*                                    setHourFieldsDa({*/}
                    {/*                                        ...hourFieldsDa,*/}
                    {/*                                        [e.toLowerCase()]: temp*/}
                    {/*                                    });*/}
                    {/*                                    // let temp2 = hourFieldsA[e.toLowerCase()];*/}
                    {/*                                    // temp.push('');*/}
                    {/*                                    // setHourFieldsA({*/}
                    {/*                                    //     ...hourFieldsA,*/}
                    {/*                                    //     [e.toLowerCase()]: temp2*/}
                    {/*                                    // });*/}
                    {/*                                }}*/}
                    {/*                                disabled={*/}
                    {/*                                    !hourFieldsDa[e.toLowerCase()][i] ||*/}
                    {/*                                    !hourFieldsA[e.toLowerCase()][i]*/}
                    {/*                                }*/}
                    {/*                            >*/}
                    {/*                                {i !== hourFieldsDa[e.toLowerCase()].length - 1 ? '-' : '+'}*/}
                    {/*                            </Button>*/}
                    {/*                        </InputGroup>*/}
                    {/*                    </FormGroup>*/}
                    {/*                ))}*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*</Row>*/}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type={'submit'}>
                        OK
                    </Button>{' '}
                    <Button color="warning" onClick={() => close()}>
                        Annulla
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}
