import axios from 'axios';
const dlgurl = 'https://gemello.dlgtek.com/api/';
export const apiconfig = () => {
    return {
        baseURL:
            dlgurl || process.env.NODE_ENV === 'development'
                ? dlgurl || 'https://ilgemellodieinstein.unidoscloud.eu/backend/api/' || 'http://localhost:8000/api/'
                : 'https://ilgemellodieinstein.unidoscloud.eu/backend/api/',
        responseType: 'json'
    };
};
export const dlgEndpoint = {
    deleteWeek: 'planning/svuotaSettimana'
};
export const deleteSettimana = (date_start, date_end) => {
    return new Promise(resolve => {
        axios
            .post(apiconfig().baseURL + dlgEndpoint.deleteWeek, { date_start, date_end })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                console.error(err);
                resolve({ err });
            });
    });
};

export const addAuthToken = () => {
    if (axios.interceptors.request.handlers.length === 0) {
        axios.interceptors.request.use(
            config => {
                config.headers.Authorization = 'Bearer ' + localStorage.token;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
    }
    /*if (axios.interceptors.response.handlers.length === 0) {
        axios.interceptors.response.use( (response) => {
            return response;
        }, error => {
            let originalRequest = error.config;
            if (error.response.status == 401 && error.messaggio && error.messaggio)
            if (error.messaggio) {
                let originalRequest = error.config;
                const errore = JSON.stringify(error.messaggio);
                if (errore.codice == '401' && !originalRequest._retry) {

                }
            }
        })
    }*/
};
