import React, { useEffect } from 'react';

export default function CalendarTooltip({ eventHover, lastEventHover, mouseEnter, setState, isStudente, psicologi }) {
    // region variables

    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {}, []);
    // endregion

    // region functions

    // endregion
    if (!eventHover?.title) return;
    return (
        <div
            style={{
                maxWidth: 400,
                width: 'max-content',
                position: 'absolute',
                zIndex: eventHover ? 20 : 0,
                left: eventHover?.ev.clientX,
                transform: 'translateX(-50%) translateY(-100%)',
                top: eventHover?.ev.clientY - 15,
                opacity: eventHover ? 1 : 0,
                transition: '0.2s',
                backgroundColor: '#2a2a2a',
                color: 'white',
                padding: 6,
                borderRadius: 4
            }}
            onMouseEnter={ev => {
                ev.preventDefault();
                //     mouseEnter();
            }}
            onMouseLeave={() => setState({ eventHover: null, lastEventHover: null })}
        >
            {(eventHover?.title || lastEventHover?.title)?.split('.').map((e, ind) => (
                <span key={'tooltip2' + ind}>
                    {e?.split(':').map((el, i) => {
                        if (i % 2 === 0)
                            return (
                                <b key={'tooltip' + i}>
                                    {el}
                                    {isStudente ? ' ' : ':'}
                                </b>
                            );
                        return el.replaceAll('~', ':');
                    })}
                    <br />
                </span>
            ))}
        </div>
    );
}
