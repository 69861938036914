import React, { useEffect, useRef, useState } from 'react';
import Avviso from '../../common/Avviso';
import RichiestaConferma from '../../common/RichiestaConferma';
import CalendarTooltip from './DlgComponents/CalendarTooltip';
import ModalSpinner from '../../common/ModalSpinner';
import CalendarHeader from './DlgComponents/CalendarHeader';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import esLocale from '@fullcalendar/core/locales/it';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import ModalAssignment from './DlgComponents/ModalAssignment';
import {
    coloriEventi,
    deleteAssignmentF,
    getAssignmentF,
    insertAssignmentF,
    modifyAssignmentF
} from './DlgComponents/PlanningServices';
import { addAuthToken, apiconfig } from '../../shared/apiconfig';
import axios from 'axios';
import CIcon from '@coreui/icons-react';
import { cilBriefcase, cilPencil } from '@coreui/icons';

export default function PlanningAssignment(props) {
    // region variables
    const [modalsState, setModalsState] = useState({ avviso: {}, richiestaConferma: {}, add: { attivo: false } });
    const [reload, setReload] = useState(false);
    const [eventi, setEventi] = useState([]);
    const [restart, setRestart] = useState(false);
    const [state, setState] = useState({});
    const [studenti, setStudenti] = useState([]);
    const [userData, setUserData] = useState();
    const [docenti, setDocenti] = useState([]);
    const dispatch = useDispatch();
    const calendarComponentRef = useRef();
    let user = null;
    let isAdmin = false;
    let isStudente = false;
    let isDocente = false;
    let isTutore = false;
    if (localStorage.user) {
        isStudente = JSON.parse(localStorage.user).is_studente === 1;
    }
    if (localStorage.user) {
        isDocente = JSON.parse(localStorage.user).is_docente === 1;
    }
    if (localStorage.user) {
        isAdmin = JSON.parse(localStorage.user).superadmin === 1;
        user = JSON.parse(localStorage.user);
    }
    if (localStorage.user) {
        isTutore = JSON.parse(localStorage.user).is_tutore === 1 || JSON.parse(localStorage.user).is_genitore === 1;
    }
    if (user === null) window.location.href = '#/logout';
    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {
        dispatch(actions.setPageTitle('Calendario assegni'));
        if (!isAdmin && !isDocente && !isStudente && !isTutore) return;
        addAuthToken();
        axios.get(apiconfig().baseURL + 'user').then(res => setUserData(res.data));
        if (isDocente) caricaElencoStudentiDaDocente();
        if (isTutore) caricaElencoStudentiDaTutore();
        if (isStudente || isDocente) return;
        caricaElencoDocenti();
        caricaElencoStudenti();
    }, [isDocente, isStudente, isAdmin]);
    function caricaElencoDocenti() {
        let params;
        let path;
        path = 'users';
        params = { params: { is_docente: 1 } };
        addAuthToken();
        axios
            .get(apiconfig().baseURL + path, params)
            .then(response => {
                setDocenti(response.data);
                setState({
                    ...state,
                    caricamentoDocenti: true
                });
            })
            .catch(error => {
                setState({
                    ...state,
                    caricamentoDocenti: true
                });
            });
    }

    function caricaElencoStudenti() {
        let params;
        let path;
        path = 'users';
        params = { params: { is_studente: 1 } };
        addAuthToken();
        axios
            .get(apiconfig().baseURL + path, params)
            .then(response => {
                setStudenti(response.data);

                setState({
                    ...state,
                    caricamentoStudenti: true
                });
            })
            .catch(error => {
                setState({
                    ...state,
                    caricamentoStudenti: true
                });
            });
    }
    function caricaElencoStudentiDaDocente() {
        let path;
        path = 'studenti';
        addAuthToken();
        axios
            .get(apiconfig().baseURL + path)
            .then(response => {
                setStudenti(response.data);
                setState({
                    ...state,
                    caricamentoStudenti: true
                });
            })
            .catch(error => {
                setState({
                    ...state,
                    caricamentoStudenti: true
                });
            });
    }
    function caricaElencoStudentiDaTutore() {
        addAuthToken();
        axios
            .get(apiconfig().baseURL + 'studentitut')
            .then(response => {
                setStudenti(response.data);
                setState({
                    ...state,
                    caricamentoStudenti: true
                });
            })
            .catch(error => {
                setState({
                    ...state,
                    caricamentoStudenti: true
                });
            });
    }
    useEffect(() => {
        let docCondition = true;
        if (isAdmin) docCondition = docenti.length > 0;
        if ((!studenti?.length > 0 || !docCondition) && !isStudente && !isDocente && !isTutore) return;
        handleModalStateChange({ modaleSpinner: { attivo: true } });
        addAuthToken();
        getAssignmentF(moment(state?.filtriRicerca?.data || new Date()).format('YYYY-MM-DD')).then(res => {
            let events = [];
            res.forEach(e => {
                let doc = docenti.find(el => el.id === e.id_docente);
                let temp = { ...e };
                temp.date = e.data_planning;
                temp.start = e.data_planning + 'T' + e.start + ':00';
                temp.end = e.data_planning + 'T' + e.end + ':00';
                temp.inizio = e.start;
                let tempColor = coloriEventi[(e.materia_planning?.materia?.charCodeAt(0) || 0) % coloriEventi.length];
                temp.color = tempColor;
                temp.textColor = '#000a';
                temp.title =
                    e.tipologia +
                    '' +
                    e.materia_planning.materia +
                    ' - ' +
                    // doc?.nome +
                    // ' ' +
                    // doc?.cognome +
                    // ' - ' +
                    studenti?.find(el => el.id === e.id_studente)?.cognome +
                    ' ' +
                    studenti?.find(el => el.id === e.id_studente)?.nome +
                    ' - Voto ' +
                    e.voto;
                if (isStudente) temp.title = e.tipologia + '' + e.materia_planning.materia + ' - Voto ' + e.voto;
                temp.fine = e.end;
                events.push(temp);
            });
            handleModalStateChange({ modaleSpinner: { attivo: false } });
            setEventi(events);
        });
    }, [state?.filtriRicerca?.data, studenti?.length, docenti.length, restart]);
    // endregion

    // region functions
    const toggleAvviso = () => {
        let temp = { ...modalsState };
        temp.avviso = { attivo: false, titolo: '', messaggio: '', codiceMessaggio: null };
        setModalsState(temp);
    };
    const toggleRichiestaConferma = action => {
        if (action === 2) {
            handleModalStateChange({ richiestaConferma: { attiva: false } });
        }
        if (action === 1) {
            deleteAssignment(modalsState.richiestaConferma?.data);
        }
    };
    const handleModalStateChange = data => {
        setModalsState(prevState => ({ ...prevState, ...data }));
    };
    const insertAssignment = (
        {
            id_sede,
            data_planning,
            start_ora,
            start_min,
            end_ora,
            end_min,
            argomenti,
            id_docente,
            id_studente,
            note,
            voto,
            interrogazione,
            id_materia
        },
        modifica
    ) => {
        let tipologia = 0;
        if (interrogazione) tipologia = 1;
        let funcToUpdate = insertAssignmentF;
        if (modifica) funcToUpdate = modifyAssignmentF;
        let message = '';
        if (!id_sede) message = 'Devi scegliere una sede per continuare';
        if (!id_studente) message = 'Devi scegliere uno studente per continuare';
        // if (!voto) message = 'Devi inserire un voto per continuare';
        if (!id_materia) message = 'Devi inserire una materia per continuare';
        if (message)
            handleModalStateChange({
                avviso: {
                    attivo: true,
                    titolo: 'Errore',
                    messaggio: message,
                    codiceMessaggio: 404
                },
                // add: { attivo: false },
                richiestaConferma: { attivo: false }
            });
        else
            funcToUpdate(
                {
                    id_sede,
                    data_planning,
                    start_ora,
                    end_ora,
                    start_min,
                    argomenti,
                    end_min,
                    id_docente,
                    id_studente,
                    tipologia,
                    note,
                    voto,
                    id_materia
                },
                modifica
            )
                .then(res => {
                    handleModalStateChange({
                        avviso: {
                            attivo: true,
                            titolo: 'Successo',
                            messaggio: 'Verifica inserita con successo',
                            codiceMessaggio: null
                        },
                        add: { attivo: false }
                    });
                    setRestart(!restart);
                })
                .catch(msg => {
                    handleModalStateChange({
                        avviso: {
                            attivo: true,
                            titolo: 'Errore',
                            messaggio: msg,
                            codiceMessaggio: 404
                        },
                        add: { attivo: false },
                        richiestaConferma: { attivo: false }
                    });
                });
    };
    function deleteAssignment(id) {
        deleteAssignmentF(id)
            .then(() => {
                handleModalStateChange({
                    avviso: {
                        attivo: true,
                        titolo: 'Successo',
                        messaggio: 'Verifica cancellata con successo',
                        codiceMessaggio: null
                    },
                    add: { attivo: false },
                    richiestaConferma: { attivo: false }
                });
                setRestart(!restart);
            })
            .catch(msg => {
                handleModalStateChange({
                    avviso: {
                        attivo: true,
                        titolo: 'Errore',
                        messaggio: msg,
                        codiceMessaggio: 404
                    },
                    add: { attivo: false },
                    richiestaConferma: { attivo: false }
                });
            });
    }
    // endregion
    return (
        <div>
            <Avviso
                attivo={modalsState.avviso.attivo}
                toggle={toggleAvviso}
                titolo={modalsState.avviso.titolo}
                messaggio={modalsState.avviso.messaggio}
            />
            <RichiestaConferma
                attivo={modalsState.richiestaConferma.attiva}
                toggle={toggleRichiestaConferma}
                titolo={modalsState.richiestaConferma.titolo}
                messaggio={modalsState.richiestaConferma.messaggio}
            />
            <CalendarTooltip
                lastEventHover={state.lastEventHover}
                eventHover={state.eventHover}
                setState={data => setState(data)}
                mouseEnter={() => {
                    setState({ ...state, eventHover: state.lastEventHover });
                }}
            />
            <ModalSpinner attivo={modalsState.modaleSpinner?.attivo} />
            <ModalAssignment
                isStudente={isStudente || isTutore}
                toggleAvviso={(titolo, messaggio) =>
                    handleModalStateChange({ avviso: { attivo: true, titolo, messaggio } })
                }
                isAdmin={isAdmin}
                isTutore={isTutore}
                studentiTut={studenti}
                isDocente={isDocente}
                attivo={modalsState.add.attivo}
                userData={userData}
                data={modalsState.add?.data}
                toggle={data =>
                    handleModalStateChange({ add: { ...modalsState.add, attivo: !modalsState?.add?.attivo } })
                }
                deleteElement={data => {
                    let endText = data?.studente_planning?.cognome + ' ' + data?.studente_planning?.nome;
                    if (studenti?.length > 0 && data.id_studente) {
                        let find = studenti.find(el => el.id === data.id_studente);
                        if (find) endText = find.cognome + ' ' + find.nome;
                    }
                    handleModalStateChange({
                        richiestaConferma: {
                            attiva: true,
                            titolo: 'Attenzione',
                            data: data.ID,
                            messaggio: "Si sta cercando di eliminare l'appuntamento con " + endText
                        }
                    });
                }}
                modifica={(data, id) => insertAssignment(data, id)}
                conferma={data => insertAssignment(data, false)}
            />
            <Row>
                <Col xs="12" sm="12" md="12">
                    <Card className={'calendarCard'} style={{ height: window.innerHeight - 140 }}>
                        <>
                            <CalendarHeader
                                setReload={() => setReload(!reload)}
                                state={state}
                                handleChangeDataFine={() => {}}
                                handleChangeDataInizio={() => {}}
                                handleChangeDataRicerca={() => {}}
                                handleChangefiltroRicercaMaterie={() => {}}
                                handleChangefiltroRicercaStudenti={() => {}}
                                handleChangefiltroRicercaSedi={() => {}}
                                handleChangefiltroRicercaOnLine={() => {}}
                                handleChangefiltroRicercaPresenza={() => {}}
                                handleChangeModalitaVista={() => {}}
                                caricaDatiMaster={() => {}}
                                caricaDatiSettimaneSuccessive={() => {}}
                                handleChangefiltroRicercaDocenti={() => {}}
                                user={user}
                                setFilter={res => setState({ ...state, openFilter: !state.openFilter })}
                                isAssignment
                                isAdmin={false}
                            />
                            <CardBody id={'calendar-dlg'}>
                                <FullCalendar
                                    defaultView="timeGridWeek"
                                    allDaySlot={false}
                                    dateClick={arg => {
                                        handleModalStateChange({ add: { attivo: true, data: arg } });
                                    }}
                                    eventClick={arg => {
                                        const currentEvent = eventi.find(e => {
                                            return e.ID == arg.event._def.extendedProps.ID;
                                        });
                                        let temp = { ...currentEvent };
                                        temp.isModify = true;
                                        temp.date = new Date(temp.start);
                                        handleModalStateChange({ add: { attivo: true, data: temp } });
                                    }}
                                    aspectRatio={3}
                                    expandRows
                                    plugins={[
                                        timeGridPlugin,
                                        dayGridPlugin,
                                        interactionPlugin,
                                        listPlugin,
                                        bootstrapPlugin
                                    ]}
                                    timeg
                                    weekends={true}
                                    events={eventi}
                                    themeSystem="bootstrap"
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
                                    }}
                                    displayEventEnd={true}
                                    locale={esLocale}
                                    slotMinTime={'09:00:00'}
                                    slotMaxTime={'22:00:00'}
                                    businessHours={{
                                        daysOfWeek: [1, 2, 3, 4, 5, 6],
                                        startTime: '09:00:00',
                                        endTime: '22:00:00'
                                    }}
                                    ref={calendarComponentRef || (() => {})}
                                    eventContent={info => {
                                        return (
                                            <div
                                                className={'cursor-pointer overflow-hidden'}
                                                style={{ height: 'inherit' }}
                                                onMouseLeave={() => {
                                                    setState({ ...state, eventHover: null, lastEventHover: null });
                                                }}
                                                onMouseEnter={ev => {
                                                    let obj;
                                                    if (!isStudente)
                                                        obj = {
                                                            title:
                                                                'Orario: ' +
                                                                info.timeText
                                                                    .replaceAll('-', ' / ')
                                                                    .replaceAll(':', '~') +
                                                                '.Materia: ' +
                                                                info.event.title
                                                                    .slice(1, info.event.title.length)
                                                                    .split('-')[0] +
                                                                '.' +
                                                                'Studente: ' +
                                                                info.event.title
                                                                    .slice(1, info.event.title.length)
                                                                    .split('-')[1] +
                                                                '. Voto: ' +
                                                                info.event.title
                                                                    .slice(1, info.event.title.length)
                                                                    .split('-')[2]
                                                                    .replace('Voto', '') +
                                                                '.Tipologia: ' +
                                                                (info.event.title.slice(0, 1) === '1'
                                                                    ? 'Orale'
                                                                    : 'Scritto'),
                                                            ev
                                                        };
                                                    else
                                                        obj = {
                                                            title:
                                                                'Orario: ' +
                                                                info.timeText
                                                                    .replaceAll('-', ' / ')
                                                                    .replaceAll(':', '~') +
                                                                '. Materia: ' +
                                                                info.event.title
                                                                    .slice(1, info.event.title.length)
                                                                    .split('-')[0] +
                                                                '. Voto: ' +
                                                                info.event.title
                                                                    .slice(1, info.event.title.length)
                                                                    .split('-')[1]
                                                                    .replace('Voto', '') +
                                                                '. Tipologia: ' +
                                                                (info.event.title.slice(0, 1) === '1'
                                                                    ? 'Orale'
                                                                    : 'Scritto'),
                                                            ev
                                                        };
                                                    setState({
                                                        ...state,
                                                        eventHover: obj,
                                                        lastEventHover: obj
                                                    });
                                                }}
                                            >
                                                {info.event.title.slice(0, 1) === '1' ? (
                                                    <CIcon icon={cilBriefcase} />
                                                ) : (
                                                    <CIcon icon={cilPencil} />
                                                )}
                                                &nbsp;
                                                {info.event.title.slice(1, info.event.title.length)}
                                            </div>
                                        );
                                    }}
                                    datesSet={(date, type) => {
                                        if (state?.filtriRicerca?.data !== date.start) {
                                            setState({
                                                ...state,
                                                filtriRicerca: {
                                                    ...state.filtriRicerca,
                                                    data: moment(date.start).add(5, 'days').toDate()
                                                }
                                            });
                                        }
                                    }}
                                />
                            </CardBody>
                        </>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
