import React, { useEffect } from 'react';
import { CardHeader, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

export default function CardHeaderMateria({ ricerca }) {
    return (
        <CardHeader>
            <h3>Filtri di ricerca</h3>
            <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                <Row>
                    <Col xs="12" sm="6" md="6">
                        <FormGroup check className="checkbox">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="checkDisattivate"
                                name="checkDisattivate"
                                onChange={ricerca}
                            />
                            <Label check className="form-check-label" htmlFor="checkDisattivate">
                                Mostra disattivate
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </CardHeader>
    );
}
