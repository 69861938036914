import React from 'react';
import { connect } from 'react-redux';
import RichiestaConferma from '../../common/RichiestaConferma';
import Avviso from '../../common/Avviso';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../../shared/apiconfig';
import * as actions from '../../store/actions/'; // Redux
import { updateObject } from '../../shared/utility';
import ModaleAllegati from '../../common/ModaleAllegati';
import AllegatiContent from './Components/AllegatiContent';
import AllegatiHeader from './Components/AllegatiHeader';
import { downloadAllegato, downloadAllegatoMaster, URL_ALLEGATI } from '../../services/commons';
import ModaleVisualizzaAllegato from './Components/ModaleVisualizzaAllegato';
import ModalSpinner from '../../common/ModalSpinner';

class ArchivioAllegati extends React.Component {
    constructor(props) {
        super(props);
        this.toggleAvviso = this.toggleAvviso.bind(this);
        this.toggleRichiestaConferma = this.toggleRichiestaConferma.bind(this);
        this.confermaAzione = this.confermaAzione.bind(this);
        this.setStateWRicerca = this.setStateWRicerca.bind(this);
        this.state = {
            listaAllegati: [],
            totaleItemsRicerca: 0,
            paginaRicerca: 1,
            itemsPerPagina: 20,
            indiciPagine: [],
            modaleSpinner: {
                attivo: false
            },
            filtriRicerca: {
                tipiAllegati: [],
                scuole: [],
                docenti: [],
                classi: [],
                materie: [],
                semplificati: true,
                nonSemplificati: true,
                descrizione: ''
            },
            richiestaConferma: {
                attiva: false,
                titolo: '',
                messaggio: '',
                tipo: 0,
                azione: null // 1=disattiva tipo, 2=elimina tipo, 3=disattiva scuola, 4=elimina scuola
            },
            modaleRinomina: {
                attiva: false,
                titolo: '',
                messaggio: '',
                nome: '',
                tipo: null
            },
            avviso: {
                attivo: false,
                titolo: '',
                messaggio: ''
            },
            idAllegatoModificato: 0,
            idTipoAllegatoNuovo: '',
            idMateriaAllegatoNuovo: '',
            idClasseAllegatoNuovo: '',
            idScuolaAllegatoNuovo: '',
            descrAllegatoNuovo: '',
            nomeFileAllegatoNuovo: '',
            modaleAllegatiAttiva: false,
            idAllegatoInAttesa: null
        };
    }

    //region functions

    aggiornaRicerca() {
        const filtri = {
            tipiAllegati: JSON.stringify(this.state.filtriRicerca.tipiAllegati.map(el => el.id)),
            materie: JSON.stringify(this.state.filtriRicerca.materie.map(el => el.id)),
            classi: JSON.stringify(this.state.filtriRicerca.classi.map(el => el.id)),
            docenti: JSON.stringify(this.state.filtriRicerca.docenti.map(el => el.id)),
            scuole: JSON.stringify(this.state.filtriRicerca.scuole.map(el => el.id)),
            semplificati: this.state.filtriRicerca.semplificati ? 1 : 0,
            sostegno: this.state.filtriRicerca.sostegno ? 1 : 0,
            nonsemplificati: this.state.filtriRicerca.nonSemplificati ? 1 : 0,
            descrizione: this.state.filtriRicerca.descrizione
        };
        /* RIPRISTINARE if (this.state.filtriRicerca.tipiAllegati.length == 0 &&
				this.state.filtriRicerca.materie.length == 0 &&
				this.state.filtriRicerca.classi.length == 0 &&
				this.state.filtriRicerca.scuole.length == 0 &&
				this.state.filtriRicerca.descrizione == ""
		) {
			this.setState({
				listaAllegati: []
			});
			return;
		}*/
        const params = { params: filtri };
        axios
            .get(apiconfig().baseURL + 'listaAllegati?page=' + this.state.paginaRicerca, params)
            .then(response => {
                this.setState({
                    listaAllegati: response.data.data,
                    totaleItemsRicerca: response.data.total
                });
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    avviso: {
                        attivo: true,
                        titolo: 'ATTENZIONE - Rilevato un errore',
                        messaggio: error.errore
                    }
                });
            });
    }

    componentDidMount() {
        this.props.setTitle('Archivio documenti');
        addAuthToken();
        this.aggiornaRicerca();
    }

    toggleRichiestaConferma = risp => {
        const modRichiestaConferma = updateObject(this.state.richiestaConferma, {
            attiva: false,
            titolo: '',
            messaggio: '',
            tipo: 0,
            conferma: risp === 1,
            codiceMessaggio: null
        });
        this.setState(
            {
                richiestaConferma: modRichiestaConferma
            },
            this.confermaAzione
        );
    };

    confermaAzione = () => {
        if (this.state.richiestaConferma.conferma) {
            switch (this.state.richiestaConferma.azione) {
                case 1:
                    this.confermaDisattiva();
                    break;
                case 2:
                    this.confermaRemoveAllegato();
                    break;
                default:
                    return;
            }
        }
    };

    toggleAvviso = () => {
        const modAvviso = {
            attivo: false,
            titolo: '',
            messaggio: '',
            codiceMessaggio: null
        };
        this.setState({
            avviso: modAvviso
        });
    };

    setStateWRicerca = state => {
        this.setState(state, this.aggiornaRicerca);
    };

    scaricaAllegato = (id, nome) => {
        downloadAllegatoMaster(id, nome, URL_ALLEGATI.BASE)
            .then(data => {
                if (data)
                    this.setState({
                        modaleVisualizzaAllegato: data,
                        modaleVisualizzaAllegatoIsPdf: nome.includes('.pdf'),
                        modaleVisualizzaAllegatoName: nome
                    });
            })
            .finally(() => {
                this.setState({ modaleSpinner: { attivo: false } });
            });
    };

    removeAllegato(arg) {
        this.setState({
            richiestaConferma: {
                attiva: true,
                titolo: 'Richiesta conferma',
                messaggio: 'Eliminare questo documento?',
                azione: 2,
                idAllegato: arg
            }
        });
    }

    confermaRemoveAllegato = () => {
        axios
            .post(apiconfig().baseURL + 'rimuoviAnagrAllegati/' + this.state.richiestaConferma.idAllegato, {})
            .then(() => {
                this.aggiornaRicerca();
            })
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status == 401) {
                    this.props.onInvalidToken(err, this.props.location.pathname, this.state);
                    this.props.history.push('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore
                        }
                    });
                }
            });
    };

    suCompletamentoUploadModale = idFile => {
        this.setState({
            idAllegatoInAttesa: idFile.uploadResponse.data
        });
    };

    toggleModaleAllegati = () => {
        this.setState({
            modaleAllegatiAttiva: false,
            idAllegatoInAttesa: null
        }); // aggiornare elenco allegati
    };

    handleChangeTipoAllegato = arg => {
        this.setState({
            idTipoAllegatoNuovo: arg.target.value
        });
    };

    handleChangeScuolaAllegato = arg => {
        this.setState({
            idScuolaAllegatoNuovo: arg.target.value
        });
    };

    handleChangeDescrAllegato = arg => {
        this.setState({
            descrAllegatoNuovo: arg.target.value
        });
    };

    handleChangeMateriaAllegato = arg => {
        this.setState({
            idMateriaAllegatoNuovo: arg.target.value
        });
    };

    suInvioAllegatoTmp = idFile => {
        this.setState({
            idAllegatoInAttesa: idFile.uploadResponse.data
        });
    };

    confermaAllegato = () => {
        this.setState({
            modaleAllegatiAttiva: false
        });
        if (
            !this.state.idAllegatoInAttesa &&
            (!this.state.idAllegatoModificato || this.state.idAllegatoModificato == 0)
        ) {
            return;
        }
        axios
            .post(apiconfig().baseURL + 'confermaDocumento', {
                idFile: this.state.idAllegatoInAttesa,
                idAllegatoModificato: this.state.idAllegatoModificato,
                id_tipo: this.state.idTipoAllegatoNuovo,
                id_scuola: this.state.idScuolaAllegatoNuovo,
                classe: this.state.idClasseAllegatoNuovo,
                id_materia: this.state.idMateriaAllegatoNuovo,
                descrizione: this.state.descrAllegatoNuovo
            })
            .then(() => {
                this.setState({
                    idAllegatoInAttesa: null
                });
                this.aggiornaRicerca();
            });
    };

    handleChangeClasseAllegato = arg => {
        this.setState({
            idClasseAllegatoNuovo: arg.target.value
        });
    };
    //endregion

    render() {
        return (
            <div>
                <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConferma.attiva}
                    toggle={this.toggleRichiestaConferma}
                    titolo={this.state.richiestaConferma.titolo}
                    messaggio={this.state.richiestaConferma.messaggio}
                />
                <ModalSpinner attivo={this.state.modaleSpinner?.attivo} />
                <ModaleAllegati
                    path={'arcg'}
                    attivo={this.state.modaleAllegatiAttiva}
                    toggle={this.toggleModaleAllegati}
                    idTipoAllegatoNuovo={this.state.idTipoAllegatoNuovo}
                    handleChangeTipoAllegato={this.handleChangeTipoAllegato}
                    idMateriaAllegatoNuovo={this.state.idMateriaAllegatoNuovo}
                    handleChangeMateriaAllegato={this.handleChangeMateriaAllegato}
                    classeAllegatoNuovo={this.state.idClasseAllegatoNuovo}
                    handleChangeClasseAllegato={this.handleChangeClasseAllegato}
                    idScuolaAllegatoNuovo={this.state.idScuolaAllegatoNuovo}
                    handleChangeScuolaAllegato={this.handleChangeScuolaAllegato}
                    descrAllegatoNuovo={this.state.descrAllegatoNuovo}
                    handleChangeDescrAllegato={this.handleChangeDescrAllegato}
                    nomeFileAllegatoNuovo={this.state.nomeFileAllegatoNuovo}
                    fileUploaderParams={{}}
                    fileUploaderPath="allegatoTmp"
                    suCompletamento={this.suCompletamentoUploadModale}
                    conferma={this.confermaAllegato}
                    idAllegatoModificato={this.state.idAllegatoModificato}
                    selAllegatoEsistente={false}
                    mostraSelAllegatoEsistente={false}
                    tipoAllegato={'allegatiAnagrafica'}
                />
                <AllegatiHeader state={this.state} setState={this.setStateWRicerca} />
                <ModaleVisualizzaAllegato
                    data={this.state.modaleVisualizzaAllegato}
                    isPdf={this.state.modaleVisualizzaAllegatoIsPdf}
                    name={this.state.modaleVisualizzaAllegatoName}
                    close={() => {
                        this.setState({ modaleVisualizzaAllegato: null });
                    }}
                />
                <AllegatiContent
                    archivio
                    state={this.state}
                    setState={data => this.setState(data)}
                    setStateWRicerca={this.setStateWRicerca}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tokenAttivo: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: titolo => dispatch(actions.setPageTitle(titolo)),
        onInvalidToken: (errore, path, state) => dispatch(actions.gestisciErrore(errore, path, state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivioAllegati);
