import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Label,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Form,
    FormGroup,
    Input,
    Button,
    InputGroup,
    FormText
} from 'reactstrap';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../../shared/apiconfig';
import { updateObject, manageNulls, calcolaCodiceFiscale, convertiDataPerDB } from '../../shared/utility';
import * as actions from '../../store/actions/'; // Redux

import RichiestaConferma from '../../common/RichiestaConferma';
import Avviso from '../../common/Avviso';
import SceltaComune from '../../common/SceltaComune';
import NotificaModifiche from '../../common/NotificaModifiche';
import ModaleModificaPassword from './ModaleModificaPassword';
import 'react-widgets/styles.css';
import withRouter from '../../common/witthRouter';
import DropdownList from 'react-widgets/DropdownList';
import DateTimePicker from 'react-widgets/DatePicker';
import moment from 'moment/moment';
import { downloadPDF } from '../../common/services';
const classError = 'border-red-important';

class Profilo extends Component {
    constructor(props) {
        super(props);
        this.toggleAvviso = this.toggleAvviso.bind(this);
        this.confermaCambioPw = this.confermaCambioPw.bind(this);
        this.changePwInputHandler = this.changePwInputHandler.bind(this);
        this.handlePwAttualeChange = this.handlePwAttualeChange.bind(this);
        this.handleNuovaPwChange = this.handleNuovaPwChange.bind(this);
        this.handleNuovaPw2Change = this.handleNuovaPw2Change.bind(this);
        this.inserisciCodiceFiscale = this.inserisciCodiceFiscale.bind(this);
        this.verificaErrori = this.verificaErrori.bind(this);
        this.verificaCodiceFiscale = this.verificaCodiceFiscale.bind(this);
        this.state = {
            utente: {},
            errori: {
                erroreComune: {
                    errore: false,
                    descrizione: 'Inserisci il comune di residenza'
                },
                erroreComuneNascita: {
                    errore: false,
                    descrizione: 'Inserisci il comune di nascita'
                },
                erroreSesso: {
                    errore: false,
                    descrizione: 'Inserisci il sesso per continuare'
                },
                erroreDataNascita: {
                    errore: false,
                    descrizione: 'Inserisci la data di nascita per continuare'
                },
                erroreCodiceFiscaleNonValido: {
                    errore: false,
                    descrizione: 'Codice Fiscale non valido'
                },
                erroreTelefono: {
                    errore: false,
                    descrizione: 'Inserisci il numero di telefono per continuare'
                },
                erroreIndirizzo: {
                    errore: false,
                    descrizione: "Inserisci l'indirizzo per continuare"
                },
                erroreCap: {
                    errore: false,
                    descrizione: 'Inserisci il cap per continuare'
                },
                erroreCodiceFiscaleAssente: {
                    errore: false,
                    descrizione: 'Inserisci il codice fiscale'
                },
                erroreCodiceFiscaleNonCoerente: {
                    errore: false,
                    descrizione: 'Codice Fiscale non valido'
                },
                erroreEmail: {
                    errore: false,
                    descrizione: 'Inserisci una mail per continuare'
                }
            },
            avviso: {
                attivo: false,
                titolo: '',
                messaggio: ''
            },
            richiestaConferma: {
                attiva: false,
                titolo: '',
                messaggio: '',
                azione: null // Vedi confermaAzione()
            },
            sceltaComuneAttiva: false,
            modifica: false,
            sedi: [],
            materie: [],
            nuovaDataNascita: null,
            richiestaConfermaSalvataggio: false, // In caso di errori non bloccanti
            modaleCambioPw: {
                attiva: false
            },
            cambiopw: {
                pwAttuale: '',
                nuovaPw: '',
                nuovaPw2: '',
                verificaPwAttuale: false, // vero se manca password attuale (nella modale)
                verificaPw1: false, // vero se manca prima password nuova (nella modale)
                verificaPw2: true // vero se nuove password diverse (nella modale)
            }
        };
    }
    user = localStorage.user ? JSON.parse(localStorage.user) : {};

    componentDidMount() {
        this.props.setTitle('Profilo utente');
        this.setState({ localUser: this.user });
        addAuthToken();
        if (
            localStorage.getItem('redirPath') === this.props.location.pathname &&
            localStorage.getItem('redirState') !== ''
        ) {
            const statoSalvato = JSON.parse(localStorage.getItem('redirState'));
            this.setState({
                utente: statoSalvato.utente,
                modifica: statoSalvato.modifica,
                sedi: statoSalvato.sedi,
                materie: statoSalvato.materie,
                richiestaConferma: statoSalvato.richiestaConferma
            });
            localStorage.removeItem('redirPath');
            localStorage.removeItem('redirState');
        } else {
            this.setState(
                {
                    utente: { id: this.props.match.params.idutente }
                },
                () => this.caricaDati()
            );
        }
    }
    verificaErrori() {
        let errori = { ...this.state.errori };
        let { dataNascita, comune, comuneNascita, email, codice_fiscale, cellulare, indirizzo, cap, sesso } =
            this.state.utente;
        errori.erroreComune.errore = !comune;
        errori.erroreComuneNascita.errore = !comuneNascita;
        errori.erroreDataNascita.errore = !dataNascita;
        errori.erroreEmail.errore = !email;
        errori.erroreCodiceFiscaleAssente.errore = !codice_fiscale;
        errori.erroreIndirizzo.errore = !indirizzo;
        errori.erroreCap.errore = !cap;
        errori.erroreSesso.errore = !sesso;
        if (this.user.is_tutore || this.user.is_genitore) {
            errori.erroreTelefono.errore = !cellulare;
        } else {
            errori.erroreTelefono.errore = false;
        }
        this.setState({ errori });
    }
    caricaDati() {
        axios
            .get(apiconfig().baseURL + 'user')
            .then(response => {
                this.setState(
                    {
                        utente: response?.data?.id ? response?.data : this.user
                    },
                    () => {
                        console.info(response);
                    }
                );
            })
            .catch(err => {
                if (err.response && err.response.status && err.response.status == 401) {
                    this.props.navigate('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore
                        }
                    });
                }
            });
    }

    toggleAvviso = () => {
        const modAvviso = {
            attivo: false,
            titolo: '',
            messaggio: ''
        };
        this.setState({
            avviso: modAvviso
        });
    };

    toggleRichiestaConferma = risp => {
        const modRichiestaConferma = updateObject(this.state.richiestaConferma, {
            attiva: false,
            titolo: '',
            messaggio: '',
            conferma: risp === 1
        });
        this.setState(
            {
                richiestaConferma: modRichiestaConferma
            },
            this.confermaAzione
        );
    };

    confermaAzione = () => {
        this.saveClickHandler();
    };

    scegliComune = () => {
        this.setState({
            sceltaComuneAttiva: true
        });
    };

    toggleSceltaComune = () => {
        this.setState({
            sceltaComuneAttiva: false
        });
    };

    toggleModaleModPassword = () => {
        const newModaleCambioPw = updateObject(this.state.modaleCambioPw, {
            attiva: false
        });
        this.setState({
            modaleCambioPw: newModaleCambioPw
        });
    };

    confermaSceltaComune = datiComune => {
        if (!datiComune.errore) {
            const nuovoUtente = updateObject(this.state.utente, {
                id_comune: datiComune.idNuovoComune,
                comune: datiComune.nuovoComuneNonCodificato
                    ? datiComune.nuovoComuneNonCodificato
                    : datiComune.nomeNuovoComune,
                prov: datiComune.nuovaProvNonCodificata ? datiComune.nuovaProvNonCodificata : datiComune.nuovaProv
            });
            this.setState({
                utente: nuovoUtente,
                sceltaComuneAttiva: false,
                modifica: true
            });
        } else {
            this.props.onInvalidToken(null, this.props.location.pathname, this.state);
            this.props.navigate('/logout');
        }
    };

    annullaHandler() {
        const utenteReset = updateObject(this.state.utente, {
            id: this.props.match.params.idutente
        });
        this.setState(
            {
                utente: utenteReset,
                modifica: false
            },
            this.caricaDati
        );
    }

    changeInputHandler(field, e) {
        const nuovoUtente = this.state.utente;
        nuovoUtente[field] = e.target.value;
        this.setState(
            {
                utente: nuovoUtente,
                modifica: true
            },
            this.verificaErrori
        );
    }

    handlePwAttualeChange(e) {
        this.changePwInputHandler('pwAttuale', e.target.value);
    }

    handleNuovaPwChange(e) {
        this.changePwInputHandler('nuovaPw', e.target.value);
    }

    handleNuovaPw2Change(e) {
        this.changePwInputHandler('nuovaPw2', e.target.value);
    }

    changePwInputHandler(field, val) {
        const nuovoStato = updateObject(this.state.cambiopw, {});
        nuovoStato[field] = val;
        this.setState(
            {
                cambiopw: nuovoStato
            },
            this.verificaCambioPw
        );
    }

    verificaCambioPw = () => {
        const nuovoStato = updateObject(this.state.cambiopw, {});
        if (this.state.cambiopw.pwAttuale != '') {
            nuovoStato.verificaPwAttuale = true;
        } else {
            nuovoStato.verificaPwAttuale = false;
        }
        if (this.state.cambiopw.nuovaPw.length < 8) {
            nuovoStato.verificaPw1 = false;
        } else {
            nuovoStato.verificaPw1 = true;
        }
        if (this.state.cambiopw.nuovaPw == this.state.cambiopw.nuovaPw2) {
            nuovoStato.verificaPw2 = true;
        } else {
            nuovoStato.verificaPw2 = false;
        }
        this.setState({
            cambiopw: nuovoStato
        });
    };

    cambiaPwHandler = () => {
        const newModaleCambioPw = updateObject(this.state.modaleCambioPw, {
            attiva: true
        });
        this.setState({
            modaleCambioPw: newModaleCambioPw
        });
    };

    confermaCambioPw = () => {
        const modPw = {
            pw_attuale: this.state.cambiopw.pwAttuale,
            nuova_pw: this.state.cambiopw.nuovaPw
        };
        axios
            .patch(apiconfig().baseURL + 'userauth', modPw)
            .then(response => {
                this.setState({
                    avviso: {
                        attivo: true,
                        titolo: 'Operazione eseguita',
                        messaggio: 'Cambio password eseguito correttamente.'
                    },
                    modaleCambioPw: {
                        attiva: false
                    }
                });
            })
            .catch(err => {
                if (err.response && err.response.status && err.response.status == 401) {
                    this.props.onInvalidToken(err, this.props.location.pathname, this.state);
                    this.props.navigate('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore
                        },
                        modaleCambioPw: {
                            attiva: false
                        }
                    });
                }
            });
    };

    saveClickHandler() {
        const utenteMod = manageNulls({
            telefono: this.state.utente.telefono,
            cellulare: this.state.utente.cellulare,
            indirizzo: this.state.utente.indirizzo,
            cap: this.state.utente.cap,
            id_comune: this.state.utente.id_comune,
            comune: this.state.utente.comune,
            prov: this.state.utente.prov
        });
        axios
            .patch(apiconfig().baseURL + 'userdata', utenteMod)
            .then(response => {
                this.setState({
                    avviso: {
                        attivo: true,
                        titolo: 'Operazione eseguita',
                        messaggio: 'Salvataggio eseguito correttamente.'
                    },
                    modifica: false
                });
            })
            .catch(err => {
                if (err.response && err.response.status && err.response.status == 401) {
                    this.props.onInvalidToken(err, this.props.location.pathname, this.state);
                    this.props.navigate('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore
                        }
                    });
                }
            });
    }
    inserisciCodiceFiscale() {
        if (
            this.state.utente.dataNascita === null ||
            this.state.utente.dataNascita === '' ||
            this.state.utente.codice_comuneNascita === null ||
            this.state.utente.codice_comuneNascita === '' ||
            this.state.utente.sesso === null ||
            this.state.utente.sesso === '' ||
            this.state.utente.cognome === null ||
            this.state.utente.cognome === '' ||
            this.state.utente.nome === null ||
            this.state.utente.nome === ''
        ) {
            this.setState({
                avviso: {
                    attivo: true,
                    titolo: 'Operazione non possibile',
                    messaggio:
                        'Per il calcolo del codice fiscale occorre inserire cognome, nome, data di nascita, comune di nascita e sesso'
                }
            });
        } else {
            const codiceFiscaleCalcolato = calcolaCodiceFiscale(
                convertiDataPerDB(this.state.utente.dataNascita),
                this.state.utente.codice_comuneNascita,
                this.state.utente.sesso,
                this.state.utente.cognome,
                this.state.utente.nome
            );
            const nuovoUtente = updateObject(this.state.utente, {
                codice_fiscale: codiceFiscaleCalcolato
            });
            const errori = updateObject(this.state.errori, {});
            errori.erroreCodiceFiscaleAssente.errore = false;
            errori.erroreCodiceFiscaleNonValido.errore = false;
            errori.erroreCodiceFiscaleNonCoerente.errore = false;
            this.setState({
                utente: nuovoUtente,
                errori: errori
            });
        }
    }

    verificaCodiceFiscale() {
        if (
            this.state.utente.codice_fiscale === null ||
            this.state.utente.codice_fiscale === '' ||
            this.state.utente.dataNascita === null ||
            this.state.utente.dataNascita === '' ||
            this.state.utente.codice_comuneNascita === null ||
            this.state.utente.codice_comuneNascita === '' ||
            this.state.utente.sesso === null ||
            this.state.utente.sesso === '' ||
            this.state.utente.cognome === null ||
            this.state.utente.cognome === '' ||
            this.state.utente.nome === null ||
            this.state.utente.nome === ''
        ) {
            this.setState({
                erroreCodiceFiscaleNonCoerente: updateObject(this.state.errori.erroreCodiceFiscaleNonCoerente, {
                    errore: false
                })
            });
        } else {
            const verifica =
                this.state.utente.codice_fiscale ===
                calcolaCodiceFiscale(
                    convertiDataPerDB(this.state.utente.dataNascita),
                    this.state.utente.codice_comuneNascita,
                    this.state.utente.sesso,
                    this.state.utente.cognome,
                    this.state.utente.nome
                );
            const errori = updateObject(this.state.errori, {});
            errori.erroreCodiceFiscaleNonCoerente.errore = !verifica;
            if (this.state.utente.is_studente) errori.erroreCodiceFiscaleNonCoerente.errore = false;
            this.setState({
                errori: errori
            });
        }
    }

    render() {
        const styleBold = {
            fontWeight: 'bold'
        };
        return (
            <div style={{ overflow: 'visible', height: window.innerHeight - 100 }}>
                <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConferma.attiva}
                    toggle={this.toggleRichiestaConferma}
                    titolo={this.state.richiestaConferma.titolo}
                    messaggio={this.state.richiestaConferma.messaggio}
                />
                <SceltaComune
                    attivo={this.state.sceltaComuneAttiva}
                    toggle={this.toggleSceltaComune}
                    conferma={this.confermaSceltaComune}
                />
                <ModaleModificaPassword
                    attivo={this.state.modaleCambioPw.attiva}
                    toggle={this.toggleModaleModPassword}
                    conferma={this.confermaCambioPw}
                    pwAttuale={this.state.cambiopw.pwAttuale}
                    nuovaPw={this.state.cambiopw.nuovaPw}
                    nuovaPw2={this.state.cambiopw.nuovaPw2}
                    handlePwAttualeChange={this.handlePwAttualeChange}
                    handleNuovaPwChange={this.handleNuovaPwChange}
                    handleNuovaPw2Change={this.handleNuovaPw2Change}
                    verificaPwAttuale={this.state.cambiopw.verificaPwAttuale}
                    verificaPw1={this.state.cambiopw.verificaPw1}
                    verificaPw2={this.state.cambiopw.verificaPw2}
                />
                <Row>
                    <Col xs="12" sm="12" md="12" style={{ padding: 16 }}>
                        <Form action="" onSubmit={e => e.preventDefault()} className="form-horizontal">
                            <Card style={{ overflow: 'visible' }}>
                                <CardHeader style={{ fontWeight: 'bold' }}>
                                    Completa il profilo per acccedere
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs="12" sm="12" md="12">
                                            <FormGroup>
                                                <Label>Nome</Label>{' '}
                                                <Label style={styleBold}>
                                                    {this.state.utente.nome + ' ' + this.state.utente.cognome}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="12">
                                            <NotificaModifiche datoModificato={this.state.modifica} />
                                        </Col>
                                        <Col xs="12" sm="12" md="12">
                                            <FormGroup>
                                                <Label htmlFor="comune">Comune di nascita</Label>
                                                <DropdownList
                                                    filter
                                                    className={
                                                        this.state.errori.erroreComuneNascita?.errore
                                                            ? 'border-red-important'
                                                            : ''
                                                    }
                                                    busy={this.state.ricercaComuneNascitaInCorso}
                                                    data={this.state.comuniNascita}
                                                    messages={{
                                                        emptyFilter: 'Nessun risultato trovato',
                                                        emptyList: 'Inserisci almeno tre caratteri'
                                                    }}
                                                    valueField="id"
                                                    textField="comune"
                                                    renderListItem={item => {
                                                        let option = item.item;
                                                        return (
                                                            <span key={'nascita' + option.id}>
                                                                {option.comune} ({option.prov})
                                                            </span>
                                                        );
                                                    }}
                                                    value={
                                                        this.state.utente.comuneNascita !== 0
                                                            ? this.state.utente.comuneNascita
                                                            : null
                                                    }
                                                    onSearch={val => {
                                                        if (val.length === 3) {
                                                            this.setState({
                                                                ricercaComuneNascitaInCorso: true
                                                            });
                                                            axios
                                                                .get(apiconfig().baseURL + 'comuni?testoRicerca=' + val)
                                                                .then(res => {
                                                                    this.setState({
                                                                        comuniNascita: res.data,
                                                                        ricercaComuneNascitaInCorso: false
                                                                    });
                                                                });
                                                        }
                                                        /*comuni?testoRicerca=asd*/
                                                    }}
                                                    onChange={val => {
                                                        let utente2 = { ...this.state.utente };
                                                        utente2.comuneNascita = val.comune;
                                                        utente2.idComuneNascita = val.id;
                                                        utente2.provNascita = val.prov;
                                                        utente2.codice_comuneNascita = val.codice;
                                                        this.setState({ utente: utente2 }, () => this.verificaErrori());
                                                    }}
                                                />
                                                {this.state.errori.erroreComuneNascita.errore ? (
                                                    <FormText style={{ marginTop: -0 }} color="danger">
                                                        {this.state.errori.erroreComuneNascita.descrizione}
                                                    </FormText>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="4" sm="2" md="2">
                                            <FormGroup>
                                                <Label htmlFor="sesso">Sesso</Label>
                                                <Input
                                                    className={
                                                        this.state.errori.erroreSesso?.errore
                                                            ? 'border-red-important'
                                                            : ''
                                                    }
                                                    type="select"
                                                    name="selectSesso"
                                                    id="selectSesso"
                                                    value={this.state.utente.sesso}
                                                    onChange={this.changeInputHandler.bind(this, 'sesso')}
                                                >
                                                    <option value=""> </option>
                                                    <option value="M">M</option>
                                                    <option value="F">F</option>
                                                </Input>
                                                {this.state.errori.erroreSesso.errore ? (
                                                    <FormText style={{ marginTop: -0 }} color="danger">
                                                        {this.state.errori.erroreSesso.descrizione}
                                                    </FormText>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="8" sm="4" md="4">
                                            <FormGroup>
                                                <Label htmlFor="date-input" className="mr-1">
                                                    Data di nascita
                                                </Label>
                                                <DateTimePicker
                                                    id="date-input"
                                                    time={false}
                                                    className={
                                                        this.state.errori.erroreDataNascita?.errore
                                                            ? 'border-red-important'
                                                            : ''
                                                    }
                                                    value={this.state.utente.dataNascita || null}
                                                    placeholder={'DD/MM/YYYY'}
                                                    format={{ raw: 'dd/MM/yyyy' }}
                                                    onChange={(_, label) => {
                                                        let utente = { ...this.state.utente };
                                                        utente.dataNascita = moment(label, 'DD/MM/YYYY').toDate();
                                                        this.setState({ utente }, this.verificaErrori);
                                                    }}
                                                />
                                                {this.state.errori.erroreDataNascita?.errore ? (
                                                    <FormText color="danger">
                                                        {this.state.errori.erroreDataNascita.descrizione}
                                                    </FormText>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="codice_fiscale">Codice fiscale</Label>
                                                <InputGroup>
                                                    <Input
                                                        type="text"
                                                        name="codice_fiscale"
                                                        disabled
                                                        className={
                                                            this.state.errori.erroreCodiceFiscaleNonValido.errore ||
                                                            (this.state.errori.erroreCodiceFiscaleAssente.errore &&
                                                                !this.props.patient) ||
                                                            this.state.errori.erroreCodiceFiscaleNonCoerente.error
                                                                ? classError
                                                                : null
                                                        }
                                                        id="codice_fiscale"
                                                        value={this.state.utente.codice_fiscale || ''}
                                                        onChange={this.changeInputHandler.bind(this, 'codice_fiscale')}
                                                    />
                                                    {/*<InputGroupAddon addonType="append">*/}
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={this.inserisciCodiceFiscale}
                                                    >
                                                        Calcola
                                                    </Button>
                                                    {/*</InputGroupAddon>*/}
                                                </InputGroup>
                                                {this.state.errori.erroreCodiceFiscaleAssente.errore &&
                                                !this.props.patient ? (
                                                    <FormText color="danger">
                                                        {this.state.errori.erroreCodiceFiscaleAssente.descrizione}
                                                    </FormText>
                                                ) : null}
                                                {this.state.errori.erroreCodiceFiscaleNonValido.errore ? (
                                                    <FormText color="danger">
                                                        {this.state.errori.erroreCodiceFiscaleNonValido.descrizione}
                                                    </FormText>
                                                ) : null}
                                                {!this.state.errori.erroreCodiceFiscaleNonValido.errore &&
                                                this.state.errori.erroreCodiceFiscaleNonCoerente.errore ? (
                                                    <FormText color="danger">
                                                        {this.state.errori.erroreCodiceFiscaleNonCoerente.descrizione}
                                                    </FormText>
                                                ) : null}
                                            </FormGroup>
                                        </Col>

                                        <Col xs="4" sm="4" md="4">
                                            <div>
                                                <FormGroup>
                                                    <Label htmlFor="cellulare">Email.</Label>
                                                    <Input
                                                        type="text"
                                                        id="email"
                                                        className={
                                                            this.state.errori.erroreEmail.errore ? classError : null
                                                        }
                                                        name="email"
                                                        placeholder="Email"
                                                        value={this.state.utente.email || ''}
                                                        onChange={this.changeInputHandler.bind(this, 'email')}
                                                    />
                                                    {this.state.errori.erroreEmail.errore && (
                                                        <FormText color="danger">
                                                            {this.state.errori.erroreEmail.descrizione}
                                                        </FormText>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="indirizzo">Indirizzo</Label>
                                                <Input
                                                    type="text"
                                                    id="indirizzo"
                                                    name="indirizzo"
                                                    className={
                                                        this.state.errori.erroreIndirizzo.errore ? classError : null
                                                    }
                                                    placeholder="Indirizzo"
                                                    value={this.state.utente.indirizzo || ''}
                                                    onChange={this.changeInputHandler.bind(this, 'indirizzo')}
                                                />
                                                {this.state.errori.erroreIndirizzo.errore && (
                                                    <FormText color="danger">
                                                        {this.state.errori.erroreIndirizzo.descrizione}
                                                    </FormText>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="2" sm="2" md="2">
                                            <FormGroup>
                                                <Label htmlFor="cap">CAP</Label>
                                                <Input
                                                    type="text"
                                                    id="cap"
                                                    name="cap"
                                                    placeholder="CAP"
                                                    className={this.state.errori.erroreCap.errore ? classError : null}
                                                    value={this.state.utente.cap || ''}
                                                    onChange={this.changeInputHandler.bind(this, 'cap')}
                                                />
                                                {this.state.errori.erroreCap.errore && (
                                                    <FormText color="danger">
                                                        {this.state.errori.erroreCap.descrizione}
                                                    </FormText>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="12">
                                            <FormGroup>
                                                <Label htmlFor="comune">Comune</Label>
                                                <DropdownList
                                                    filter
                                                    className={
                                                        this.state.errori.erroreComune?.errore
                                                            ? 'border-red-important'
                                                            : ''
                                                    }
                                                    busy={this.state.ricercaComuneInCorso}
                                                    data={this.state.comuni}
                                                    messages={{
                                                        emptyFilter: 'Nessun risultato trovato',
                                                        emptyList: 'Inserisci almeno tre caratteri'
                                                    }}
                                                    valueField="id"
                                                    textField="comune"
                                                    renderListItem={item => {
                                                        let option = item.item;
                                                        return (
                                                            <span key={'com' + option.id}>
                                                                {option.comune} ({option.prov})
                                                            </span>
                                                        );
                                                    }}
                                                    value={
                                                        this.state.utente.comune !== 0 ? this.state.utente.comune : null
                                                    }
                                                    onSearch={val => {
                                                        if (val.length === 3) {
                                                            this.setState({
                                                                ricercaComuneInCorso: true
                                                            });
                                                            axios
                                                                .get(apiconfig().baseURL + 'comuni?testoRicerca=' + val)
                                                                .then(res => {
                                                                    this.setState({
                                                                        comuni: res.data,
                                                                        ricercaComuneInCorso: false
                                                                    });
                                                                });
                                                        }
                                                        /*comuni?testoRicerca=asd*/
                                                    }}
                                                    onChange={val => {
                                                        let utente2 = { ...this.state.utente };
                                                        utente2.comune = val.comune;
                                                        utente2.idComune = val.id;
                                                        utente2.prov = val.prov;
                                                        utente2.codice_comune = val.codice;
                                                        this.setState({ utente: utente2 }, this.verificaErrori);
                                                    }}
                                                />
                                                {this.state.errori.erroreComune.errore ? (
                                                    <FormText style={{ marginTop: -0 }} color="danger">
                                                        {this.state.errori.erroreComune.descrizione}
                                                    </FormText>
                                                ) : null}
                                            </FormGroup>
                                        </Col>

                                        <Col xs="3" sm="3" md="3">
                                            <FormGroup>
                                                <Label htmlFor="cellulare">Cell.</Label>
                                                <Input
                                                    className={
                                                        this.state.errori.erroreTelefono?.errore
                                                            ? 'border-red-important'
                                                            : ''
                                                    }
                                                    type="text"
                                                    id="cellulare"
                                                    name="cellulare"
                                                    placeholder="Cellulare"
                                                    value={this.state.utente.cellulare || ''}
                                                    onChange={this.changeInputHandler.bind(this, 'cellulare')}
                                                />
                                                {this.state.errori.erroreTelefono?.errore && (
                                                    <FormText style={{ marginTop: -0 }} color="danger">
                                                        {this.state.errori.erroreTelefono.descrizione}
                                                    </FormText>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="3" sm="3" md="3">
                                            <FormGroup>
                                                <Label htmlFor="telefono">Tel.</Label>
                                                <Input
                                                    type="text"
                                                    id="telefono"
                                                    name="telefono"
                                                    placeholder="Telefono"
                                                    value={this.state.utente.telefono || ''}
                                                    onChange={this.changeInputHandler.bind(this, 'telefono')}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className={'d-flex justify-content-end'}>
                                        {this.user.is_docente ? (
                                            <Button
                                                color={'primary'}
                                                style={{ marginRight: 12 }}
                                                onClick={() => {
                                                    axios
                                                        .get(apiconfig().baseURL + 'docente/downloadModuloPrivacy', {
                                                            responseType: 'blob'
                                                        })
                                                        .then(response => {
                                                            downloadPDF(response.data, 'Privacy doc.pdf');
                                                        });
                                                }}
                                            >
                                                Scarica il modulo privacy
                                            </Button>
                                        ) : null}
                                        <Button
                                            className={'success-dlg-button'}
                                            onClick={() => {
                                                let bodyData = {
                                                    abilitato: '1',
                                                    attivo: '1',
                                                    cap: this.state.utente.cap,
                                                    cellulare: this.state.utente.cellulare,
                                                    codice_comune: this.state.utente.codice_comune,
                                                    codice_comune_nascita: this.state.utente.codice_comuneNascita,
                                                    codice_fiscale: this.state.utente.codice_fiscale,
                                                    cognome: this.state.utente.cognome,
                                                    comune: this.state.utente.comune,
                                                    comune_nascita: this.state.utente.comuneNascita,
                                                    email: this.state.utente.email,
                                                    id: this.state.utente.id,
                                                    id_comune: this.state.utente.idComune,
                                                    indirizzo: this.state.utente.indirizzo,
                                                    nome: this.state.utente.nome,
                                                    prov: this.state.utente.prov,
                                                    sesso: this.state.utente.sesso,
                                                    telefono: this.state.utente.telefono,
                                                    username: this.state.utente.username
                                                };
                                                bodyData.abilitato = '1';
                                                bodyData.attivo = '1';
                                                bodyData.comune_nascita = this.state.utente.comuneNascita;
                                                bodyData.id_comune_nascita = this.state.utente.idComuneNascita;
                                                bodyData.prov_nascita = this.state.utente.provNascita;
                                                bodyData.data_nascita = moment(this.state.utente.dataNascita).format(
                                                    'YYYY-MM-DD'
                                                );
                                                bodyData.id_comune =
                                                    this.state.utente.id_comune || this.state.utente.idComune;
                                                this.verificaErrori();
                                                let errore = false;
                                                Object.keys(this.state.errori).map(key => {
                                                    errore = errore || this.state.errori[key].errore;
                                                });
                                                if (errore)
                                                    this.setState({
                                                        avviso: {
                                                            attivo: true,
                                                            messaggio: 'Ci sono degli errori di compilazione',
                                                            titolo: 'Errore'
                                                        }
                                                    });
                                                else
                                                    axios
                                                        .patch(
                                                            apiconfig().baseURL + 'users/' + this.state.utente.id,
                                                            bodyData
                                                        )
                                                        .then(res => {
                                                            window.location.reload();
                                                        })
                                                        .catch(err => {
                                                            console.error(err, Object.values(err.response.data));
                                                            let errori = Object.values(err.response.data);
                                                            let temp = '';
                                                            if (errori)
                                                                errori.forEach(el => {
                                                                    if (el.length > 0)
                                                                        el.forEach(err => {
                                                                            temp += err + ' ';
                                                                        });
                                                                });
                                                            this.setState({
                                                                avviso: {
                                                                    attivo: true,
                                                                    messaggio: temp
                                                                        ? temp.replace(
                                                                              'The email has already been taken',
                                                                              'Email già registrata'
                                                                          )
                                                                        : 'Errore Imprevisto',
                                                                    titolo: 'Errore'
                                                                }
                                                            });
                                                        });
                                            }}
                                        >
                                            Salva i dati
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tokenAttivo: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: titolo => dispatch(actions.setPageTitle(titolo)),
        onInvalidToken: (errore, path, state) => dispatch(actions.gestisciErrore(errore, path, state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profilo));
