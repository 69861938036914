export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const pathDownload = () => {
    return {
        baseURL:
            process.env.NODE_ENV === 'development'
                ? 'http://localhost/web/tmp/'
                : 'https://ilgemellodieinstein.unidoscloud.eu/tmp/'
    };
};

export const manageNulls = currObject => {
    let newObject = {};
    Object.keys(currObject).forEach(key => {
        if (currObject[key] !== null) newObject[key] = currObject[key];
    });
    return newObject;
};

export const emailValidate = email => {
    return email.match(/^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,4}$/);
};

export const codFiscaleValidate = codFiscale => {
    return codFiscale.match(/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/);
};

export const calcolaCodiceFiscale = (data_nascita, comune, sesso, cognome, nome) => {
    let gs = 0;
    let i = 0;
    let somma = 0;

    let strCodFis = '';
    let strcognome = '';
    let strnome = '';
    let strgiornosex = '';
    let chrcontrollo = '';
    let risultato = '';
    cognome = cognome?.toUpperCase() || '';
    nome = nome?.toUpperCase() || '';

    if (cognome && nome) {
        // Data di nascita
        let data_nascita_split = data_nascita.split('-');
        let giorno = data_nascita_split[2];
        let annoCento = data_nascita_split[0][0] + data_nascita_split[0][1];
        let annoDieci = data_nascita_split[0][2];
        let annoZero = data_nascita_split[0][3];
        let mese = data_nascita_split[1];

        //----------------------------------------------------------------

        // Processa il cognome
        //----------------------------------------------------------------
        for (i = 0; i < cognome?.length; i++) {
            switch (cognome?.charAt(i)) {
                case 'A':
                case 'E':
                case 'I':
                case 'O':
                case 'U':
                    break;
                default:
                    if (cognome?.charAt(i) <= 'Z' && cognome?.charAt(i) > 'A')
                        strcognome = strcognome + cognome?.charAt(i);
            }
        }
        if (strcognome?.length < 3) {
            for (i = 0; i < cognome?.length; i++) {
                switch (cognome?.charAt(i)) {
                    case 'A':
                    case 'E':
                    case 'I':
                    case 'O':
                    case 'U':
                        strcognome = strcognome + cognome?.charAt(i);
                        break;
                    default:
                        break;
                }
            }
            if (strcognome?.length < 3) {
                for (i = strcognome?.length; i <= 3; i++) {
                    strcognome = strcognome + 'X';
                }
            }
        }
        strcognome = strcognome?.substring(0, 3);
        //------------------------------------------------------------

        // processa il nome
        //----------------------------------------------------------------
        for (i = 0; i < nome?.length; i++) {
            switch (nome?.charAt(i)) {
                case 'A':
                case 'E':
                case 'I':
                case 'O':
                case 'U':
                    break;
                default:
                    if (nome?.charAt(i) <= 'Z' && nome?.charAt(i) > 'A') strnome = strnome + nome?.charAt(i);
            }
        }
        if (strnome?.length > 3) {
            strnome = strnome?.substring(0, 1) + strnome?.substring(2, 4);
        } else {
            if (strnome?.length < 3) {
                for (i = 0; i < nome?.length; i++) {
                    switch (nome?.charAt(i)) {
                        case 'A':
                        case 'E':
                        case 'I':
                        case 'O':
                        case 'U':
                            strnome = strnome + nome?.charAt(i);
                            break;
                        default:
                            break;
                    }
                }
                if (strnome?.length < 3) {
                    for (i = strnome?.length; i <= 3; i++) {
                        strnome = strnome + 'X';
                    }
                }
            }
            strnome = strnome?.substring(0, 3);
        }
        //--------------------------------------- Fine processa nome

        // processa giorno e sesso
        //--------------------------------------------
        if (sesso === 'F') {
            gs = parseInt(giorno) + 40;
        } else {
            gs = parseInt(giorno);
        }

        if (gs < 10) strgiornosex = '0' + gs;
        else strgiornosex = gs;
        //--------------------------------------------

        // Mese
        switch (mese) {
            case '01':
            case '1':
                mese = 'A';
                break;
            case '02':
            case '2':
                mese = 'B';
                break;
            case '03':
            case '3':
                mese = 'C';
                break;
            case '04':
            case '4':
                mese = 'D';
                break;
            case '05':
            case '5':
                mese = 'E';
                break;
            case '06':
            case '6':
                mese = 'H';
                break;
            case '07':
            case '7':
                mese = 'L';
                break;
            case '08':
            case '8':
                mese = 'M';
                break;
            case '09':
            case '9':
                mese = 'P';
                break;
            case '10':
                mese = 'R';
                break;
            case '11':
                mese = 'S';
                break;
            case '12':
                mese = 'T';
                break;
            default:
                break;
        }
        //--------------------------------------------

        strCodFis = strcognome + strnome + annoDieci + annoZero + mese + strgiornosex + comune;

        // calcola la cifra di controllo
        //--------------------------------------------
        for (i = 0; i < 15; i++) {
            if ((i + 1) % 2 !== 0) {
                //caratteri dispari
                switch (strCodFis.charAt(i)) {
                    case '0':
                    case 'A': {
                        somma += 1;
                        break;
                    }
                    case '1':
                    case 'B': {
                        somma += 0;
                        break;
                    }
                    case '2':
                    case 'C': {
                        somma += 5;
                        break;
                    }
                    case '3':
                    case 'D': {
                        somma += 7;
                        break;
                    }
                    case '4':
                    case 'E': {
                        somma += 9;
                        break;
                    }
                    case '5':
                    case 'F': {
                        somma += 13;
                        break;
                    }
                    case '6':
                    case 'G': {
                        somma += 15;
                        break;
                    }
                    case '7':
                    case 'H': {
                        somma += 17;
                        break;
                    }
                    case '8':
                    case 'I': {
                        somma += 19;
                        break;
                    }
                    case '9':
                    case 'J': {
                        somma += 21;
                        break;
                    }
                    case 'K': {
                        somma += 2;
                        break;
                    }
                    case 'L': {
                        somma += 4;
                        break;
                    }
                    case 'M': {
                        somma += 18;
                        break;
                    }
                    case 'N': {
                        somma += 20;
                        break;
                    }
                    case 'O': {
                        somma += 11;
                        break;
                    }
                    case 'P': {
                        somma += 3;
                        break;
                    }
                    case 'Q': {
                        somma += 6;
                        break;
                    }
                    case 'R': {
                        somma += 8;
                        break;
                    }
                    case 'S': {
                        somma += 12;
                        break;
                    }
                    case 'T': {
                        somma += 14;
                        break;
                    }
                    case 'U': {
                        somma += 16;
                        break;
                    }
                    case 'V': {
                        somma += 10;
                        break;
                    }
                    case 'W': {
                        somma += 22;
                        break;
                    }
                    case 'X': {
                        somma += 25;
                        break;
                    }
                    case 'Y': {
                        somma += 24;
                        break;
                    }
                    case 'Z': {
                        somma += 23;
                        break;
                    }
                    default:
                        break;
                }
            } //caratteri pari
            else {
                switch (strCodFis.charAt(i)) {
                    case '0':
                    case 'A': {
                        somma += 0;
                        break;
                    }
                    case '1':
                    case 'B': {
                        somma += 1;
                        break;
                    }
                    case '2':
                    case 'C': {
                        somma += 2;
                        break;
                    }
                    case '3':
                    case 'D': {
                        somma += 3;
                        break;
                    }
                    case '4':
                    case 'E': {
                        somma += 4;
                        break;
                    }
                    case '5':
                    case 'F': {
                        somma += 5;
                        break;
                    }
                    case '6':
                    case 'G': {
                        somma += 6;
                        break;
                    }
                    case '7':
                    case 'H': {
                        somma += 7;
                        break;
                    }
                    case '8':
                    case 'I': {
                        somma += 8;
                        break;
                    }
                    case '9':
                    case 'J': {
                        somma += 9;
                        break;
                    }
                    case 'K': {
                        somma += 10;
                        break;
                    }
                    case 'L': {
                        somma += 11;
                        break;
                    }
                    case 'M': {
                        somma += 12;
                        break;
                    }
                    case 'N': {
                        somma += 13;
                        break;
                    }
                    case 'O': {
                        somma += 14;
                        break;
                    }
                    case 'P': {
                        somma += 15;
                        break;
                    }
                    case 'Q': {
                        somma += 16;
                        break;
                    }
                    case 'R': {
                        somma += 17;
                        break;
                    }
                    case 'S': {
                        somma += 18;
                        break;
                    }
                    case 'T': {
                        somma += 19;
                        break;
                    }
                    case 'U': {
                        somma += 20;
                        break;
                    }
                    case 'V': {
                        somma += 21;
                        break;
                    }
                    case 'W': {
                        somma += 22;
                        break;
                    }
                    case 'X': {
                        somma += 23;
                        break;
                    }
                    case 'Y': {
                        somma += 24;
                        break;
                    }
                    case 'Z': {
                        somma += 25;
                        break;
                    }
                    default:
                        break;
                }
            }
        }
        somma %= 26;
        switch (somma) {
            case 0: {
                chrcontrollo = 'A';
                break;
            }
            case 1: {
                chrcontrollo = 'B';
                break;
            }
            case 2: {
                chrcontrollo = 'C';
                break;
            }
            case 3: {
                chrcontrollo = 'D';
                break;
            }
            case 4: {
                chrcontrollo = 'E';
                break;
            }
            case 5: {
                chrcontrollo = 'F';
                break;
            }
            case 6: {
                chrcontrollo = 'G';
                break;
            }
            case 7: {
                chrcontrollo = 'H';
                break;
            }
            case 8: {
                chrcontrollo = 'I';
                break;
            }
            case 9: {
                chrcontrollo = 'J';
                break;
            }
            case 10: {
                chrcontrollo = 'K';
                break;
            }
            case 11: {
                chrcontrollo = 'L';
                break;
            }
            case 12: {
                chrcontrollo = 'M';
                break;
            }
            case 13: {
                chrcontrollo = 'N';
                break;
            }
            case 14: {
                chrcontrollo = 'O';
                break;
            }
            case 15: {
                chrcontrollo = 'P';
                break;
            }
            case 16: {
                chrcontrollo = 'Q';
                break;
            }
            case 17: {
                chrcontrollo = 'R';
                break;
            }
            case 18: {
                chrcontrollo = 'S';
                break;
            }
            case 19: {
                chrcontrollo = 'T';
                break;
            }
            case 20: {
                chrcontrollo = 'U';
                break;
            }
            case 21: {
                chrcontrollo = 'V';
                break;
            }
            case 22: {
                chrcontrollo = 'W';
                break;
            }
            case 23: {
                chrcontrollo = 'X';
                break;
            }
            case 24: {
                chrcontrollo = 'Y';
                break;
            }
            case 25: {
                chrcontrollo = 'Z';
                break;
            }
            default:
                break;
        }
        //--------------------------------------------

        //document.FormCodFis.CodiceFiscale.value = strCodFis + chrcontrollo;
        risultato = strCodFis + chrcontrollo;
    }
    return risultato;
};

export const tipiUtente = [
    '',
    'Docente',
    'Studente',
    'Insegnante di sostegno',
    'Psicologo',
    'Paziente',
    'Genitore',
    '',
    '',
    '',
    'Responsabile'
];

export const convertiDataPerDB = data => {
    const d = new Date(data);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

export const convertiDataDaDB = data => {
    return data && data != '' ? data.substr(8) + '/' + data.substr(5, 2) + '/' + data.substr(0, 4) : '';
};

export const getDataDaDB = data => {
    var dateParts = data.split('-');
    var y = parseInt(dateParts[0], 10);
    var m = parseInt(dateParts[1], 10);
    var d = parseInt(dateParts[2], 10);
    return new Date(y, m - 1, d);
};

export const formatDataConGiornoSettimana = data => {
    const d = new Date(data);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    const giorni = ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'];
    return giorni[d.getDay()] + ' ' + day + '/' + month + '/' + year;
};

export const formatDataSoloGiornoSettimana = data => {
    const d = new Date(data);
    const giorni = ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'];
    return giorni[d.getDay()];
};
