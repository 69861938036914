import React, { useEffect } from 'react';
import { DropdownToggle } from 'reactstrap';

export default function AdminCalendarDropDown({ state }) {
    // region variables

    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {}, []);
    // endregion

    // region functions

    // endregion
    return (
        <>
            <div style={{ width: window.innerWidth > 600 ? 40 : 20 }}></div>
            <DropdownToggle
                style={{
                    borderRadius: 24,
                    paddingLeft: 2,
                    boxShadow: 'none',
                    background: 'transparent',
                    paddingRight: 2,
                    paddingTop: 1,
                    paddingBottom: 0,
                    marginBottom: 22
                }}
            >
                {state.openFilter ? (
                    <i
                        // className={'fa fa-minus'}
                        className={'fa fa-minus my-2 px-2 cursor-pointer bg-light '}
                        style={{ color: '#006b9f', fontSize: 22, boxShadow: '1px 1px 2px #62a2c2c4', borderRadius: 8 }}
                    />
                ) : (
                    <i
                        className={'fa fa-plus my-2 px-2 cursor-pointer bg-light'}
                        style={{ color: '#006b9f', fontSize: 22, boxShadow: '1px 1px 2px #62a2c2c4', borderRadius: 8 }}
                    />
                )}
            </DropdownToggle>
        </>
    );
}
