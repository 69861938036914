import React, { useEffect, useState } from 'react';
import EvaluationAdminNew from './EvaluationAdminNew';
import Avviso from '../../common/Avviso';
import axios from 'axios';
import { addAuthToken, apiconfig } from '../../shared/apiconfig';
export default function EvaluationAdmin(props) {
    // region variables
    const [dataGetted, setDataGetted] = useState(false);
    const [docenti, setDocenti] = useState(null);
    const [studenti, setStudenti] = useState(null);
    const [avviso, setAvviso] = useState({
        attivo: false,
        titolo: '',
        messaggio: ''
    });
    const [materie, setMaterie] = useState([]);
    const [evaluation, setEvaluation] = useState([]);
    let is_studente = false;
    let is_admin = false;
    let is_docente = false;
    let id = false;
    let userData = null;
    if (localStorage.user) {
        userData = JSON.parse(localStorage.user);
        is_studente = userData.is_studente;
        is_admin = window.location.hash === '#/valutazioniAdmin';
        is_docente = window.location.hash === '#/valutazioniDoc';
        id = userData.id;
    }
    // endregion
    // region lifeCycle
    // onStart
    useEffect(() => {
        setDataGetted(true);
    }, []);
    useEffect(() => {
        axios.get(apiconfig().baseURL + 'materie', {}).then(response => {
            setMaterie(response.data);
        });
    }, []);
    useEffect(() => {
        if (dataGetted) {
            setDataGetted(false);
            addAuthToken();
            getData();
        }
    }, [dataGetted]);
    // useEffect(() => {
    //     if (is_docente) {
    //         getData();
    //     }
    // }, [is_docente]);
    useEffect(() => {
        if (is_studente && evaluation?.valutazioni?.filter(e => e.toNotify)?.length > 0) {
            evaluation
                .filter(e => e.toNotify)
                .map(e =>
                    axios
                        .patch(apiconfig().baseURL + 'setRichiestaVisualizzata/' + e.id)
                        // .then(res => getData())
                        .catch(console.error)
                );
        }
    }, [is_studente, evaluation]);
    // endregion

    // region functions
    function getData() {
        axios
            .get(apiconfig().baseURL + 'elencoValutazioni')
            .then(res => {
                let evaluationi = res.data;
                setEvaluation(evaluationi);
            })
            .catch(err => {
                console.error(err);
                setEvaluation([]);
                if (err?.response?.status && err.response.status == 401) {
                    window.location.href = '#/logout';
                }
            });
        axios
            .get(apiconfig().baseURL + 'users?tipo=1')
            .then(res => {
                setDocenti(res.data);
            })
            .catch(err => {
                console.error(err);
                setDocenti();
                if (err?.response?.status && err.response.status == 401) {
                    window.location.href = '#/logout';
                }
            });
        axios
            .get(apiconfig().baseURL + 'users?tipo=2')
            .then(res => {
                setStudenti(res.data);
            })
            .catch(err => {
                console.error(err);
                setStudenti();
                if (err?.response?.status && err.response.status == 401) {
                    window.location.href = '#/logout';
                }
            });
        props.getNotificationData && props.getNotificationData();
    }
    function toggleAvviso() {
        setAvviso({ attivo: false, titolo: '', messaggio: '' });
    }
    // endregion
    return (
        <div className={'pb-5'}>
            <Avviso attivo={avviso.attivo} toggle={toggleAvviso} titolo={avviso.titolo} messaggio={avviso.messaggio} />
            {(materie?.length > 0 && evaluation?.valutazioni?.length > 0) || (is_docente && !is_admin) ? (
                <EvaluationAdminNew
                    id={id}
                    userData={userData}
                    docente={is_docente}
                    materie={materie}
                    getData={() => {
                        setDataGetted(false);
                        getData();
                    }}
                    isAdmin={is_admin}
                    is_docente={is_docente}
                    docenti={docenti}
                    studenti={studenti}
                    data={evaluation}
                    notifications={
                        is_admin ? evaluation?.valutazioni : evaluation?.valutazioni?.filter(el => el.letto === 0) || []
                    }
                    setAvviso={setAvviso}
                />
            ) : (
                ''
            )}
            &nbsp;
            {materie?.length > 0 && evaluation?.valutazioni?.length > 0 && is_docente && !is_admin ? (
                <EvaluationAdminNew
                    id={id}
                    historyData
                    docente
                    materie={materie}
                    isAdmin={is_admin}
                    getData={() => {
                        setDataGetted(false);
                        getData();
                    }}
                    userData={userData}
                    docenti={docenti}
                    is_docente={is_docente}
                    data={evaluation}
                    notifications={evaluation?.valutazioni?.filter(el => el.letto === 1) || []}
                    setAvviso={setAvviso}
                />
            ) : (
                ''
            )}
            &nbsp;
        </div>
    );
}
