import axios from 'axios';

import * as actionTypes from './actionTypes';
import { apiconfig } from '../../shared/apiconfig';

let reqInterceptor;

export const authStart = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('userAuthenticated');
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        state: { ...user, token },
        token: token,
        user: user
    };
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('userAuthenticated');
    localStorage.removeItem('redirPath');
    localStorage.removeItem('redirState');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

const addInterceptor = nuovoToken => {
    reqInterceptor = axios.interceptors.request.use(
        config => {
            config.headers.Authorization = 'Bearer ' + nuovoToken;
            return config;
        },
        error => {
            return Promise.reject(error);
            /*return new Promise((resolve, reject) => {
            const originalReq = error.config;
            if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                originalReq._retry = true;
                let res = fetch(apiconfig().baseURL + 'refresh', {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Device': 'device',
                        'Token': localStorage.getItem('token')
                    },
                    redirect: 'follow',
                    referrer: 'no-referrer',
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                        refresh_token: localStorage.getItem('refresh_token')
                    }),

                }).then(res => res.json()).then(res => {
                    localStorage.setItem('token', res.token);
                    //localStorage.device = 'device';
                    return axios(originalReq);
                });
                resolve(res);
            }
            return Promise.reject(error);
        })*/
        }
    );
};

const removeInterceptor = () => {
    axios.interceptors.request.eject(reqInterceptor);
};

export const addAuthToken = nuovoToken => {
    addInterceptor(nuovoToken);
    /*axios.interceptors.response.use( response => {
        if (response.headers.Authorization !== localStorage.getItem('token')) {
            //removeInterceptor();
            addInterceptor(response.headers.Authorization);
        }
    });*/
};

/*export const removeAuthToken = () => {
    const myInterceptor = axios.interceptors.request.use(() => {
    });
    axios.interceptors.request.eject(myInterceptor);
}*/

export const gestisciErrore = (errore, path, state) => {
    return dispatch => {
        if (localStorage.getItem('token') !== null) {
            const params = {
                currState: state,
                path: path,
                currToken: localStorage.getItem('token'),
                currId: JSON.parse(localStorage.getItem('user')).id
            };
            localStorage.setItem('oldToken', params.currToken);
            localStorage.setItem('oldId', params.currId);
            axios
                .post(apiconfig().baseURL + 'salvastato', params)
                .then(() => {
                    dispatch(logout());
                })
                .catch(err => {
                    console.error(err);
                    dispatch(logout());
                });
        }
    };
};

export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: username,
            password: password,
            returnSecureToken: true,
            oldToken: localStorage.getItem('oldToken') !== null ? localStorage.getItem('oldToken') : '',
            oldId: localStorage.getItem('oldId') !== null ? localStorage.getItem('oldId') : ''
        };
        /*window.open('http://localhost:8000/api/loginGoogle', 'mywindow', "location=1,status=1,scrollbars=1, width=800,height=800");
        let listener = window.addEventListener('message', (message) => {
            window.opener.location.reload();
          });
        */
        axios
            .post(apiconfig().baseURL + 'login', authData)
            //axios.get(apiconfig().baseURL +  'googleLogin', {crossDomain: true, crossOrigin: 'anonymous',
            //            headers: {"Access-Control-Allow-Origin": "*"}})
            /*const authDataGoogle = {
            token: password
        };
        axios.post(apiconfig().baseURL +  'googleLogin', authDataGoogle)*/
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('report_auto', JSON.stringify(response.data.report_auto));
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userAuthenticated', 'true');
                localStorage.setItem('expiresIn', response.data.expiresIn);
                localStorage.removeItem('redirPath');
                localStorage.removeItem('redirState');
                localStorage.removeItem('oldToken');
                localStorage.removeItem('oldId');
                if (response.data.redirPath) {
                    localStorage.setItem('redirPath', response.data.redirPath);
                }
                if (response.data.redirState) {
                    localStorage.setItem('redirState', response.data.redirState);
                }
                if (response.data.user.superadmin == 1) {
                    localStorage.setItem('profilo', 100);
                } else if (response.data.user.is_admin == 1) {
                    localStorage.setItem('profilo', 10);
                } else if (response.data.user.is_docente == 1) {
                    localStorage.setItem('profilo', 1);
                } else if (response.data.user.is_studente == 1) {
                    localStorage.setItem('profilo', 2);
                } else if (response.data.user.is_tutore == 1) {
                    localStorage.setItem('profilo', 3);
                } else if (response.data.user.is_genitore == 1) {
                    localStorage.setItem('profilo', 5);
                } else {
                    localStorage.setItem('profilo', 0);
                }
                removeInterceptor();
                addAuthToken(response.data.token);
                dispatch(authSuccess(response.data.token, response.data.user));
                //dispatch(checkAuthTimeout((response.data.expiresIn)));
            })
            .catch(err => {
                console.error(err);
                dispatch(authFail(err));
                //dispatch(removeAuthToken());
            });
    };
};

export const setAuthRedirectPath = path => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        authRedirectPath: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate < new Date()) {
                dispatch(logout());
            } else {
                const user = localStorage.getItem('user');
                dispatch(authSuccess(token, user));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    };
};
