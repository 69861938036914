import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import MultiSelect from 'react-widgets/Multiselect';

export default function Section3({ daysMap, utente, days, setUtente, setAvviso, maggiorenne }) {
    useEffect(() => {}, []);
    const [dateData, setDateData] = useState({});
    const [giorno_incontri, setGiorno_incontri] = useState(null);
    const [nGiorni, setNgiorni] = useState(2);
    const [nGiorniChanged, setNGiorniChanged] = useState(false);
    const [load, setload] = useState(false);
    const [startedGiorno, setStartedGiorno] = useState(false);
    const [justOpen, setJustOpen] = useState(true);
    const [starteddateData, setStarteddateData] = useState(false);
    const [startedn_lezioni_settimanali, setStartedn_lezioni_settimanali] = useState(false);
    const [autorizzazione_uscita, setAutorizzazione_uscita] = useState(maggiorenne);
    useEffect(() => {
        if (utente?.giorno_incontri && !startedGiorno) {
            setGiorno_incontri(utente.giorno_incontri);
            setStartedGiorno(true);
        }
    }, [utente?.giorno_incontri]);
    useEffect(() => {
        if (utente?.dateData && Object.keys(utente?.dateData).length > 0) {
            setDateData(utente.dateData);
            setStarteddateData(false);
        }
    }, [utente?.dateData]);
    useEffect(() => {
        if (maggiorenne) {
            setUtente({ ...utente, autorizzazione_uscita: true });
        } else setUtente({ ...utente, autorizzazione_uscita });
    }, [autorizzazione_uscita]);

    useEffect(() => {
        if (utente?.n_lezioni_settimanali && !startedn_lezioni_settimanali) {
            setNgiorni((utente.n_lezioni_settimanali || 2) + '');
            setStartedn_lezioni_settimanali(true);
        }
    }, [utente?.n_lezioni_settimanali, utente]);
    useEffect(() => {
        if (utente && justOpen && (utente.autorizzazione_uscita === 1 || utente.autorizzazione_uscita === 0)) {
            setAutorizzazione_uscita(utente.autorizzazione_uscita || maggiorenne);
            setJustOpen(false);
        }
    }, [utente, utente.autorizzazione_uscita]);
    useEffect(() => {
        if (!giorno_incontri) return;
        giorno_incontri?.forEach(giorno => {
            if (!dateData[giorno.toLowerCase()]) dateData[giorno.toLowerCase()] = [];
            if (!(dateData[giorno.toLowerCase()]?.length > 0)) {
                dateData[giorno.toLowerCase()].push({ start: '', end: '' });
            }
        });
        Object.keys(dateData).forEach(key => {
            if (giorno_incontri?.findIndex(el => el.toLowerCase() === key) < 0) {
                let temp = { ...dateData };
                delete temp[key];
                setDateData(temp);
            }
        });

        setload(!load);
    }, [giorno_incontri, utente.giorno_incontri]);
    useEffect(() => {
        setUtente({ ...utente, giorni_orari: JSON.stringify(dateData), dateData });
    }, [dateData]);
    useEffect(() => {
        if (nGiorniChanged) {
            setUtente({ ...utente, n_lezioni_settimanali: nGiorni });
            setNGiorniChanged(false);
        }
    }, [nGiorni]);

    function addRow(day) {
        let temp = { ...dateData };
        if (temp[day.toLowerCase()]) temp[day.toLowerCase()].push({ start: '', end: '' });
        setDateData(temp);
    }
    function removeRow(day, i) {
        let temp = { ...dateData };
        if (temp[day.toLowerCase()]) temp[day.toLowerCase()].splice(i, 1);
        setDateData(temp);
    }
    function onStartChange(day, i, value) {
        let temp = { ...dateData };
        if (temp[day.toLowerCase()]) {
            if (!temp[day.toLowerCase()][i]) temp[day.toLowerCase()][i] = { start: '', end: '' };
            temp[day.toLowerCase()][i].start = value;
            let endValue = parseInt(value, 10) + 1;
            if (parseInt(temp[day.toLowerCase()][i].end) <= parseInt(value) || !temp[day.toLowerCase()][i].end) {
                let endString = endValue < 10 ? '0' + endValue + ':00' : endValue + ':00';
                temp[day.toLowerCase()][i].end = endString;
            }
        }
        setDateData(temp);
    }
    function onEndChange(day, i, value) {
        let temp = { ...dateData };
        if (temp[day.toLowerCase()]) {
            if (!temp[day.toLowerCase()][i]) temp[day.toLowerCase()][i] = { start: '', end: '' };
            if (parseInt(value, 10) > parseInt(temp[day.toLowerCase()][i].start))
                temp[day.toLowerCase()][i].end = value;
            else
                setAvviso({
                    attivo: true,
                    messaggio: "l'orario di fine non puo essere minore di quello di inizio",
                    titolo: 'Attenzione'
                });
        }
        setDateData(temp);
    }

    return (
        <>
            <Row>
                <Col xs={'12'} sm={'6'} md={'6'}>
                    <FormGroup>
                        <Label htmlFor="incontri_previsti">Incontri settimanali previsti</Label>
                        <Input
                            onChange={event => {
                                setNgiorni(event.target.value);
                                setNGiorniChanged(true);
                            }}
                            value={nGiorni || null}
                            type="select"
                            name="incontri_previsti"
                            id="incontri_previsti"
                            autoComplete="name"
                        >
                            {new Array(5).fill(0).map((e, i) => (
                                <option key={i + 2} value={i + 2}>
                                    {i + 2} Volte a settimana
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col xs={'12'} sm={'6'} md={'6'}>
                    <FormGroup>
                        <Label htmlFor="giorno_incontri">Giorni preferiti</Label>
                        <MultiSelect
                            id="giorno_incontri"
                            data={days}
                            value={utente.giorno_incontri}
                            textField="Giorni"
                            minLength={3}
                            onChange={array => {
                                setGiorno_incontri(array);
                                setUtente({ ...utente, giorno_incontri: array });
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {utente.giorno_incontri
                    ?.sort((a, b) => (daysMap[a] > daysMap[b] ? 1 : -1))
                    .map(e => (
                        <Col xs={'12'} sm={'6'} md={Math.floor(12 / utente.giorno_incontri.length)}>
                            <Label>Orario di {e}</Label>
                            {dateData[e.toLowerCase()]?.length > 0 &&
                                dateData[e.toLowerCase()]?.map((el, i) => (
                                    <SingleItem
                                        label={e}
                                        add={() => addRow(e.toLowerCase())}
                                        remove={() => removeRow(e.toLowerCase(), i)}
                                        dayData={dateData[e.toLowerCase()]}
                                        currentData={dateData[e.toLowerCase()][i]}
                                        values={dateData[e.toLowerCase()][i]}
                                        onChange={(type, value) => {
                                            if (type === 'start') onStartChange(e.toLowerCase(), i, value);
                                            else if (type === 'end') onEndChange(e.toLowerCase(), i, value);
                                        }}
                                    />
                                ))}
                        </Col>
                    ))}
            </Row>
            {!maggiorenne && (
                <Row>
                    <span style={{ fontWeight: 'bold' }}>
                        Al termine dell’ orario di lezione i ragazzi non saranno più sotto la sorveglianza nè la
                        responsabilità del personale.
                        <br /> Il sottoscritto genitore di ai sensi dell’art. 19 bis della L. 172/2017
                    </span>
                    <span style={{ marginLeft: 0, marginTop: 0 }}>
                        &nbsp;
                        <FormGroup check className="checkbox">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="autorizzazione"
                                name="autorizzazione"
                                checked={autorizzazione_uscita}
                                onChange={event => {
                                    setAutorizzazione_uscita(event.target.checked);
                                }}
                            />
                            <Label check className="form-check-label" htmlFor="autorizzazione">
                                Autorizza il proprio figlio/a all’uscita autonoma dai locali;
                            </Label>
                        </FormGroup>
                        &nbsp;
                        <FormGroup check className="checkbox">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="checkNonAutorizzo"
                                name="checkNonAutorizzo"
                                checked={!autorizzazione_uscita}
                                onChange={event => {
                                    setAutorizzazione_uscita(!event.target.checked);
                                }}
                            />
                            <Label check className="form-check-label" htmlFor="checkNonAutorizzo">
                                NON Autorizza il proprio figlio/a all’uscita autonoma dai locali;
                            </Label>
                        </FormGroup>
                    </span>
                </Row>
            )}
        </>
    );
}
const SingleItem = ({ label, add, remove, values, onChange, dayData }) => {
    let hours = [
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00'
    ];

    return (
        <>
            <div className={'d-flex'} style={{ alignItems: 'last baseline' }}>
                <FormGroup style={{ minWidth: 90 }}>
                    <Label style={{ marginBottom: -6, fontSize: 14, fontWeight: 'bold' }}>Dalle</Label>
                    <Input
                        onChange={event => {
                            onChange('start', event.target.value);
                        }}
                        value={values.start}
                        id={'giorno_incontri_start_' + label}
                        type={'select'}
                    >
                        <option value={''}>{''}</option>
                        {hours.map(e => (
                            <option value={e}>{e}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup style={{ minWidth: 90, marginLeft: 12 }}>
                    <Label style={{ marginBottom: -6, fontSize: 14, fontWeight: 'bold' }}>Alle</Label>
                    <Input
                        onChange={event => {
                            onChange('end', event.target.value);
                        }}
                        value={values.end}
                        id={'giorno_incontri_end_' + label}
                        type={'select'}
                    >
                        <option value={''}>{''}</option>
                        {hours.map(e => (
                            <option value={e}>{e}</option>
                        ))}
                    </Input>
                </FormGroup>
                {dayData.length === 2 && (
                    <div
                        className={'my-2 px-2 cursor-pointer mx-2 bg-light text-primary'}
                        onClick={() => {
                            remove();
                        }}
                        style={{ borderRadius: 7 }}
                    >
                        <i className={'fa fa-minus'} />
                    </div>
                )}
                {dayData.length < 2 && values?.start && values.end && (
                    <div
                        className={'my-2 px-2  mx-2  cursor-pointer bg-primary text-white'}
                        onClick={add}
                        style={{
                            borderRadius: 7,
                            visibility:
                                dayData.length >= 2 ? 'hidden' : values?.start && values.end ? 'visible' : 'hidden'
                        }}
                    >
                        <i className={'fa fa-plus'} />
                    </div>
                )}
            </div>
        </>
    );
};
