import React, { useEffect, useState } from 'react';
import { TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    ListGroupItemText,
    Row
} from 'reactstrap';
import { convertiDataDaDB } from '../../../shared/utility';
import StudentsPoints from './StudentsPoints';
import TooltipItem from '../../../common/TooltipItem';

export default function PlanningSchedaStudente({ el, props, evento, state, setState, valutazioni, idDocente, attivo }) {
    // region variables
    const [annotazioni, setAnnotazioni] = useState({});
    const [timeout, setTimeouts] = useState(null);
    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {
        if (evento) {
            setAnnotazioni(
                evento.dettagli
                    ? evento.dettagli.find(x => x.id_docente == idDocente && x.id_studente == el.id)
                        ? evento.dettagli.find(x => x.id_docente == idDocente && x.id_studente == el.id).annotazioni
                        : ''
                    : ''
            );
        }
    }, [evento, evento?.dettagli?.length]);
    // endregion

    // region functions

    // endregion
    return <></>;
}
