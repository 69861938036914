export const FormSection = [
    [
        { subtitle: 'Dati genitore 1', fontSize: 23, section: 1 },
        {
            name: '',
            label: 'tipo_genitore1',

            section: 1,
            type: 'select',
            selectOptions: [
                { value: 'Madre', label: 'Madre' },
                { value: 'Padre', label: 'Padre' },
                { value: 'Tutore legale', label: 'Tutore legale' }
            ],
            width: 'max-content'
        },
        {
            name: '',
            label: 'sesso_genitore1',
            section: 1,
            type: 'select',
            selectOptions: [
                { value: 'M', label: 'M' },
                { value: 'F', label: 'F' }
            ],
            width: 'max-content',
            condition: 'tipo_genitore1'
        }
    ],
    [
        { name: 'Nome', label: 'nome_genitore1', regex: /^[A-Za-z ']+$/, section: 1 },
        { name: 'Cognome', label: 'cognome_genitore1', regex: /^[A-Za-z ']+$/, section: 1 }
    ],
    [
        { name: 'Luogo di Nascita', label: 'luogo_di_nascita_genitore1', section: 1, type: 'city' },
        { name: 'Data di nascita', label: 'data_di_nascita_genitore1', section: 1, type: 'date' },
        { name: 'Codice Fiscale', label: 'codice_fiscale_genitore1', section: 1, type: 'fiscal' }
    ],
    [{ name: 'Email', label: 'mail_genitore1', section: 1, regex: /^[a-zA-Z0-9._-]+@[a-zA-Z]/ }],
    [{ subtitle: 'Indirizzo', fontSize: 20, section: 1 }],
    [
        { name: 'Via', label: 'via_genitore1', section: 1 },
        { name: 'Città', label: 'citta_genitore1', section: 1, type: 'city' },
        // { name: 'Provincia', label: 'provincia_genitore1', section: 1 },
        { name: 'Cap', label: 'cap_genitore1', section: 1, regex: /\d+$/ }
    ],
    [{ name: 'Telefono', label: 'telefono_genitore1', section: 1, regex: /\d+$/ }],
    [
        { subtitle: 'Dati genitore 2', fontSize: 23, section: 2 },
        {
            name: '',
            label: 'tipo_genitore2',
            section: 2,
            type: 'select',
            selectOptions: [
                { value: 'Madre', label: 'Madre' },
                { value: 'Padre', label: 'Padre' },
                { value: 'Tutore legale', label: 'Tutore legale' }
            ],
            width: 'max-content'
        },
        {
            name: '',
            label: 'sesso_genitore2',
            section: 2,
            type: 'select',
            selectOptions: [
                { value: 'M', label: 'M' },
                { value: 'F', label: 'F' }
            ],
            width: 'max-content',
            condition: 'tipo_genitore2'
        }
    ],
    [
        { name: 'Nome', regex: /^[A-Za-z ']+$/, label: 'nome_genitore2', section: 2 },
        { name: 'Cognome', regex: /^[A-Za-z ']+$/, label: 'cognome_genitore2', section: 2 }
    ],
    [
        { name: 'Luogo di Nascita', label: 'luogo_di_nascita_genitore2', section: 2, type: 'city' },
        { name: 'Data di nascita', label: 'data_di_nascita_genitore2', section: 2, type: 'date' },
        { name: 'Codice Fiscale', label: 'codice_fiscale_genitore2', section: 2, type: 'fiscal' }
    ],
    [{ name: 'Email', regex: /^[a-zA-Z0-9._-]+@[a-zA-Z]/, label: 'mail_genitore2', section: 2 }],
    [{ subtitle: 'Indirizzo', fontSize: 20, section: 2 }],
    [
        { name: 'Via', label: 'via_genitore2', section: 2 },
        { name: 'Città', label: 'citta_genitore2', section: 2, type: 'city' },
        { name: 'Cap', label: 'cap_genitore2', section: 2, regex: /\d+$/ }
    ],
    [{ name: 'Telefono', label: 'telefono_genitore2', section: 2, regex: /^\d+$/ }],
    [{ subtitle: 'Lezioni individuali', fontSize: 28, section: 3 }]
];
