import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import RichiestaConferma from '../../common/RichiestaConferma';
import ModaleRinomina from '../../common/ModaleRinomina';
import Avviso from '../../common/Avviso';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../../shared/apiconfig';
import * as actions from '../../store/actions/'; // Redux
import { updateObject } from '../../shared/utility';
import withRouter from '../../common/witthRouter';
import CardHeaderMateria from './Components/CardHeaderMateria';
import MateriaGeneralTable from './Components/MateriaGeneralTable';
import ModaleGestioneCorsi from './ModaleGestioneCorsi';

class Materie extends React.Component {
    constructor(props) {
        super(props);
        this.toggleAvviso = this.toggleAvviso.bind(this);
        this.toggleRichiestaConferma = this.toggleRichiestaConferma.bind(this);
        this.toggleModaleRinomina = this.toggleModaleRinomina.bind(this);
        this.changeModificaNomeHandler = this.changeModificaNomeHandler.bind(this);
        this.confermaAzione = this.confermaAzione.bind(this);
        this.eliminaMateria = this.eliminaMateria.bind(this);
        this.rinominaMateria = this.rinominaMateria.bind(this);
        this.aggiornaRicerca = this.aggiornaRicerca.bind(this);
        this.nuovaMateria = this.nuovaMateria.bind(this);
        this.disattivaMateria = this.disattivaMateria.bind(this);
        this.state = {
            materie: [],
            filtroRicercaDisattivate: false,
            richiestaConferma: {
                attiva: false,
                titolo: '',
                messaggio: '',
                azione: null, // 1=disattiva, 2=elimina
                codiceMessaggio: null // codifica i tipi di messaggio in caso di redirect automatico da login
            },
            modalCorso: {
                attivo: false,
                titolo: '',
                messaggio: ''
            },
            modaleRinomina: {
                attiva: false,
                titolo: '',
                messaggio: '',
                nome: '',
                codiceMessaggio: null // codifica i tipi di messaggio in caso di redirect automatico da login
            },
            avviso: {
                attivo: false,
                titolo: '',
                messaggio: '',
                codiceMessaggio: null // codifica i tipi di messaggio in caso di redirect automatico da login
            },
            idMateriaModificata: null
        };
        this.handleChangefiltroRicercaDisattivate = this.handleChangefiltroRicercaDisattivate.bind(this);
        this.onRicercaError = this.onRicercaError.bind(this);
    }
    onRicercaError(error) {
        console.error(error);
        if (error?.response?.status && error.response.status == 401) {
            this.props.onInvalidToken(error, this.props.location.pathname, this.state);
            this.props.navigate('/logout');
        } else {
            this.setState({
                avviso: {
                    attivo: true,
                    titolo: 'ATTENZIONE - Rilevato un errore',
                    messaggio: error.errore
                }
            });
        }
    }
    aggiornaRicerca() {
        let params = {};
        let paramsC = {};
        if (this.state.filtroRicercaDisattivate) {
            params = { params: { disattivate: '1' } };
        }
        if (this.state.filtroRicercaCorsiDisattivate) {
            paramsC = { params: { disattivate: '1' } };
        }
        addAuthToken();
        axios
            .get(apiconfig().baseURL + 'materie', params)
            .then(response => {
                this.setState({
                    materie: response.data
                });
            })
            .catch(this.onRicercaError);
        addAuthToken();
        axios
            .get(apiconfig().baseURL + 'corsi', params)
            .then(response => {
                this.setState({
                    corsi: response.data
                });
            })
            .catch(this.onRicercaError);
    }

    elencoMessaggi(idMessaggio, idMateriaCorrente = null) {
        const materiaCorrente =
            idMateriaCorrente !== null ? this.state.materie.find(x => x.id === idMateriaCorrente) : null;
        switch (idMessaggio) {
            case 1:
                return <div>Inserire il nome della nuova materia</div>;
            case 2:
                return (
                    <div>
                        Inserire il nuovo nome (
                        <span style={{ fontWeight: 'bold' }}>attenzione: la modifica riguarderà anche lo storico</span>)
                    </div>
                );
            case 3:
                return (
                    <div>
                        Eliminare <span style={{ fontWeight: 'bold' }}>definitivamente</span> {materiaCorrente.materia}?
                    </div>
                );
            case 4:
                return <div>Inserire il nome del nuovo corso</div>;
            default:
                return '';
        }
    }

    componentDidMount() {
        this.props.setTitle('Lista materie');
        addAuthToken();
        if (
            localStorage.getItem('redirPath') === this.props.location.pathname &&
            localStorage.getItem('redirState') !== ''
        ) {
            const statoSalvato = JSON.parse(localStorage.getItem('redirState'));
            const nuovoRichiestaConferma = updateObject(statoSalvato.richiestaConferma, {
                messaggio:
                    statoSalvato.richiestaConferma.codiceMessaggio === null
                        ? statoSalvato.richiestaConferma.messaggio
                        : this.elencoMessaggi(
                              statoSalvato.richiestaConferma.codiceMessaggio,
                              statoSalvato.idUtenteModificato
                          )
            });
            const nuovoModaleRinomina = updateObject(statoSalvato.modaleRinomina, {
                messaggio:
                    statoSalvato.modaleRinomina.codiceMessaggio === null
                        ? statoSalvato.modaleRinomina.messaggio
                        : this.elencoMessaggi(
                              statoSalvato.modaleRinomina.codiceMessaggio,
                              statoSalvato.idUtenteModificato
                          )
            });
            const nuovoAvviso = updateObject(statoSalvato.avviso, {
                messaggio:
                    statoSalvato.avviso.codiceMessaggio === null
                        ? statoSalvato.avviso.messaggio
                        : this.elencoMessaggi(statoSalvato.avviso.codiceMessaggio, statoSalvato.idUtenteModificato)
            });
            this.setState(
                {
                    materie: statoSalvato.materie,
                    filtroRicercaDisattivate: statoSalvato.filtroRicercaDisattivate,
                    idMateriaModificata: statoSalvato.idMateriaModificata,
                    richiestaConferma: nuovoRichiestaConferma,
                    modaleRinomina: nuovoModaleRinomina,
                    avviso: nuovoAvviso
                },
                this.aggiornaRicerca
            );
        } else {
            this.aggiornaRicerca();
        }
    }

    toggleRichiestaConferma = risp => {
        const modRichiestaConferma = updateObject(this.state.richiestaConferma, {
            attiva: false,
            titolo: '',
            messaggio: '',
            conferma: risp === 1,
            codiceMessaggio: null
        });
        this.setState(
            {
                richiestaConferma: modRichiestaConferma
            },
            this.confermaAzione
        );
    };

    confermaAzione = () => {
        if (this.state.richiestaConferma.conferma) {
            switch (this.state.richiestaConferma.azione) {
                case 1:
                    this.confermaDisattivaMateria();
                    break;
                case 2:
                    this.confermaEliminaMateria();
                    break;
                default:
                    return;
            }
        }
    };

    toggleAvviso = () => {
        const modAvviso = {
            attivo: false,
            titolo: '',
            messaggio: '',
            codiceMessaggio: null
        };
        this.setState({
            avviso: modAvviso
        });
    };

    toggleModaleRinomina = () => {
        const modModaleRinomina = {
            attiva: false,
            titolo: '',
            messaggio: '',
            codiceMessaggio: null
        };
        this.setState({
            modaleRinomina: modModaleRinomina
        });
    };

    nuovaMateria = corso => {
        const newModaleCorso = {
            attivo: true,
            titolo: corso ? 'Nuovo corso' : 'Nuova materia',
            messaggio: this.elencoMessaggi(corso ? 4 : 1),
            codiceMessaggio: corso ? 4 : 1,
            nome: ''
        };
        this.setState({
            modaleRinomina: newModaleCorso,
            idMateriaModificata: '0'
        });
    };

    rinominaMateria(id, corso) {
        const materiaCorrente = this.state.materie.find(x => x.id === id);
        const modModaleRinomina = {
            attivo: true,
            titolo: 'Ridenominazione',
            messaggio: this.elencoMessaggi(2),
            codiceMessaggio: 2,
            nome: materiaCorrente.materia
        };
        this.setState({
            modaleRinomina: modModaleRinomina,
            idMateriaModificata: id
        });
    }

    changeModificaNomeHandler(arg) {
        const modModaleRinomina = updateObject(this.state.modaleRinomina, {
            nome: arg.target.value
        });
        this.setState({
            modaleRinomina: modModaleRinomina
        });
    }

    confermaRinomina = () => {
        const nuovoNome = this.state.modaleRinomina.nome;
        if (nuovoNome === null || nuovoNome === '') {
            this.setState({
                avviso: {
                    attivo: true,
                    titolo: 'ATTENZIONE - Operazione non effettuata',
                    messaggio: 'Inserire il nome della materia',
                    codiceMessaggio: null
                }
            });
        } else {
            if (this.state.idMateriaModificata !== '0') {
                axios
                    .patch(apiconfig().baseURL + 'materie/' + this.state.idMateriaModificata, { materia: nuovoNome })
                    .then(response => {
                        this.aggiornaRicerca();
                        this.toggleModaleRinomina();
                    })
                    .catch(this.onRicercaError);
            } else {
                axios
                    .post(
                        apiconfig().baseURL +
                            (this.state.modaleRinomina.codiceMessaggio + '' === '4' ? 'corsi' : 'materie'),
                        {
                            materia: nuovoNome,
                            corso: nuovoNome,
                            disattivata: 0
                        }
                    )
                    .then(response => {
                        this.aggiornaRicerca();
                        this.toggleModaleRinomina();
                    })
                    .catch(this.onRicercaError);
            }
        }
    };

    eliminaMateria(id, corso) {
        const richiesta = {
            attiva: true,
            titolo: 'Richiesta conferma',
            messaggio: this.elencoMessaggi(3, id),
            codiceMessaggio: 3,
            conferma: false,
            azione: 2
        };
        this.setState({
            richiestaConferma: richiesta,
            idMateriaModificata: id
        });
    }

    confermaEliminaMateria() {
        axios
            .delete(apiconfig().baseURL + 'materie/' + this.state.idMateriaModificata)
            .then(this.aggiornaRicerca)
            .catch(this.onRicercaError);
    }

    disattivaMateria(id, corso) {
        const materiaCorrente = this.state.materie.find(x => x.id === id);
        if (!materiaCorrente.disattivata) {
            const richiesta = {
                attiva: true,
                titolo: 'Richiesta conferma',
                messaggio: 'Disattivare la materia ' + materiaCorrente.materia + '?',
                conferma: false,
                azione: 1,
                codiceMessaggio: null
            };
            this.setState({
                richiestaConferma: richiesta,
                idMateriaModificata: id
            });
        } else {
            this.setState(
                {
                    idMateriaModificata: id
                },
                this.confermaDisattivaMateria
            );
        }
    }

    confermaDisattivaMateria() {
        const materiaCorrente = this.state.materie.find(x => x.id === this.state.idMateriaModificata);
        axios
            .patch(apiconfig().baseURL + 'materie/' + this.state.idMateriaModificata, {
                disattivata: materiaCorrente.disattivata ? 0 : 1
            })
            .then(this.aggiornaRicerca)
            .catch(this.onRicercaError);
    }

    render() {
        return (
            <div>
                <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConferma.attiva}
                    toggle={this.toggleRichiestaConferma}
                    titolo={this.state.richiestaConferma.titolo}
                    messaggio={this.state.richiestaConferma.messaggio}
                />
                <ModaleGestioneCorsi
                    data={{}}
                    messaggio={''}
                    attivo={this.state.modalCorso.attivo}
                    close={() => this.setState({ modalCorso: { attivo: false } })}
                    setData={console.info}
                    titolo={'Aggiunta corso'}
                />
                <ModaleRinomina
                    attivo={this.state.modaleRinomina.attivo}
                    toggle={this.toggleModaleRinomina}
                    titolo={this.state.modaleRinomina.titolo}
                    messaggio={this.state.modaleRinomina.messaggio}
                    conferma={this.confermaRinomina}
                    nome={this.state.modaleRinomina.nome}
                    changeNomeHandler={this.changeModificaNomeHandler}
                />
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card
                            style={{
                                maxHeight: window.innerHeight - 70,
                                boxShadow: 'none',
                                border: '1px solid rgb(197, 197, 197)'
                            }}
                        >
                            <CardHeaderMateria ricerca={this.handleChangefiltroRicercaDisattivate} />
                            <CardBody>
                                <div className={'d-flex dlg-table'} style={{ maxHeight: window.innerHeight - 200 }}>
                                    <MateriaGeneralTable
                                        nuovaMateria={this.nuovaMateria}
                                        eliminaMateria={this.eliminaMateria}
                                        rinominaMateria={this.rinominaMateria}
                                        disattivaMateria={this.disattivaMateria}
                                        isCorsi
                                        btnTitle={'Nuovo Corso'}
                                        data={this.state.corsi || []}
                                        title={'Corso'}
                                        row={'materia'}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    handleChangefiltroRicercaDisattivate(arg) {
        this.setState(
            {
                filtroRicercaDisattivate: arg.target.checked
            },
            this.aggiornaRicerca
        );
    }
}

const mapStateToProps = state => {
    return {
        tokenAttivo: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: titolo => dispatch(actions.setPageTitle(titolo)),
        onInvalidToken: (errore, path, state) => dispatch(actions.gestisciErrore(errore, path, state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Materie));
